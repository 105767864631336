:root {
    --white: #FFFFFF;
    --cta1: #1A3945;
    --cta2: #379F00;
    --cta3: #64CD82;
    --secondary: #1EA896;
    --yellow: #F9B000;
    --text: #12262F;
    --gray: #959595;
    --outline: #EDF4F4;
    --inside: #FAFCFC;
    --error: #E8336E;
    --pink: #E8336E;
    --gradient: linear-gradient(113.49deg, #E8336E 10.85%, #F9B000 77.92%);
}

$white: #FFFFFF;
$cta1: #1A3945;
$cta2: #379F00;
$cta3: #64CD82;
$secondary: #1EA896;
$yellow: #F9B000;
$text: #12262F;
$gray: #959595;
$outline: #EDF4F4;
$inside: #FAFCFC;
$error: #E8336E;
$pink: #E8336E;
$gradient: linear-gradient(113.49deg, #E8336E 10.85%, #F9B000 77.92%);
