@import "../../../styles.scss";

.filter-wrapper {
    position: sticky;
    top: 30px;
    @media (max-width: 767px) {
        position: relative;
        top: 0px;
    }
    .h6 {
        @include h6-heading($text, 'OpenSans-Bold');
    }

    .p2 {
        @include p2-paragraph($text, 'OpenSans-Bold');
    }

    .p3 {
        @include p3-paragraph($text, 'OpenSans-SemiBold');
    }

    .tree-wrap {
        .p2-drop {
            font-size: 14px;
        }
    }

    .count {
        @include p3-paragraph(rgba($text, 0.53), 'OpenSans-Regular');
    }

    label {
        &.ml-2 {
            @include p3-paragraph($text, 'OpenSans-Regular');
        }
    }

    .slider-range-wrap {
        .range-slider {
            .p-slider-range {
                background-color: var(--yellow);
            }

            .p-slider-handle {
                border-color: var(--yellow);
            }

            &:not(.p-disabled) {
                .p-slider-handle:hover {
                    background-color: var(--yellow);
                    border-color: var(--yellow);
                }
            }
        }

        .slider-range-input {
            border-color: var(--outline);

            &::placeholder {
                color: var(--gray);
            }
        }
    }
}
.pricebox-p{
    position: relative;
    .pricetag-p {
        position: absolute;
        left: 10px !important;
        font-size: 14px;
        top: 18px;
        right: auto;
    }
    .slider-range-input {
        padding-left: 45px !important;
    }
}