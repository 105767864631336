@import "../../styles.scss";

.p-input-icon-right {
    .p-inputtext {
        @include p2-paragraph($text);
        height: 40px;
        border: 1px solid var(--outline);
        border-radius: 4px;
    }
    display: flex;
    align-items: center;
    justify-content: center;
}

.order-boxes {
    border: 1px solid var(--outline);
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    max-width: 1210px;
    margin-left: auto;
    .order-gray-box {
        background-color: var(--inside);
        border-bottom: 1px solid var(--outline);
        border-radius: 4px;
        padding: 12px 24px;
        @media (max-width: 576px) {
            padding: 12px 16px;
        }

        .p2-bold {
            font-family: "OpenSans-Bold";
        }
    }

}
.order-outline-box {
    padding: 10px 24px;
    @media (max-width: 576px) {
        padding: 14px 16px;
        .flex.align-items-center.justify-content-between {
            justify-content: start !important;
            width: 100%;
            .hand-on {
                max-width: 78%;
            }
        }
    }
    .order-product-img {
        width: 56px;
        height: 56px;
        border-radius: 4px;
        margin-right: 30px;
        @media (max-width: 576px) {
            margin-right: 16px;
        }
    }
    .img45{
        width: 45px;
        height: 45px;
        border-radius: 4px;
        margin-right: 10px;
    }
    .width-set{
        @media (max-width: 767px) {
            width: calc(100% - 60px);
        }
        .max-350-ellipse{
            width: auto ;
            max-width: 350px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
            vertical-align: bottom;
            @media (max-width: 576px) {
                max-width: calc(100% - 45px);
            }
        }
    }

    .p2-semibold {
        font-family: "OpenSans-SemiBold";
        &.mt-2{
            text-align: right;
        }
    }
    .flex.align-items-center.justify-content-between~.p2-semibold{        
        text-align: right;
    }
    .wrapcontent{
        cursor: pointer;
        // font-size: 14px;
        margin-bottom: 0px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        max-width: 370px;
        @media (max-width: 359px) {
            max-width: 220px;
        }
        &.heading{
            max-width: 300px;
        }
        &.w-210{
            max-width: 210px;
        }
    }
    
}