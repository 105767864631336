@import "../../../styles.scss";
.hardware-section1 {
    padding: 0px 0px 40px 0px;
    min-height: calc(100vh - 442px);
}
button.slick-arrow{
    .slick-prev.slick-disabled {
        cursor: default;
    }
    .slick-next.slick-disabled {
        cursor: default;
    }
    &.slick-disabled{
        cursor: default;
    }
}
