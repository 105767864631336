@import "../../../styles.scss";

.banner1-image-box {
    position: relative;
    height: 160px;
    overflow: hidden;
    width: 100%;
    border-radius: 0.25rem;
    margin-bottom: 60px;
    .home1-img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        transition: all 0.3s ease-in-out;
    }
    .overlay-text {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0);
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        padding: 0px 52px 0px 52px;
        @media (max-width: 767px) {
            padding: 15px 16px 15px 16px;
        }
        .h2 {
            color: $white;
            margin-bottom: 4px;
        }
        .p2 {
            color: $outline;
            line-height: 20px;
        }
    }
    &:hover {
        .home1-img {
            transform: scale(1.05);
        }
        .overlay-text {
            .link {
                color: $yellow;
                .pi {
                    color: $yellow;
                    transform: translateX(5px);
                }
            }
        }
    }
}