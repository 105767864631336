.p-component{
    font-family: 'OpenSans-Regular'; 
}
// BUTTONS
.p-button{
    font-size: 16px;
    font-family: 'OpenSans-SemiBold'; 
    background: $gradient;
    color: $white;
    border: none;
    justify-content: center;
    outline: none !important;
    box-shadow: none !important;
    &:hover,&:active,&:focus,&:focus-visible,&:enabled:hover{
        background: $gradient;
        color: $white;
        border-color: $gradient;
    }
    &.p-button-largem,&.p-button-lg{
        height: 56px;
        border-radius: 4px;
    }
    &.p-button-success{
        background: $gradient;
        border: 1px solid $yellow;
        &:hover,&:active,&:focus,&:focus-visible,&:enabled:hover{
            background: $gradient;
            border: 1px solid $yellow;
        }
    }
    &.p-button-secondary{
        background: $cta1;
        border: $cta1;
        &:hover,&:active,&:focus,&:focus-visible,&:enabled:hover{
            background: $cta1;
            border: $cta1;
        }
    }
    &.p-button-danger{
        &.p-button-outlined {
            &:hover,&:active,&:focus,&:focus-visible,&:enabled:hover{
                background: transparent;
                border-color: $error;
            }
        }
    }
    &.unfield{
        opacity: 0.5;
        pointer-events: none;
    }
    &.min-width176{
        min-width: 176px;
    }
    &.p-button-text{
        &.gray{
            color: $gray;
            background: transparent;
            &:hover{
                color: $gray;
            }
        }
        &.yellow{
            color: $yellow;
            background: transparent;
            &:hover{
                color: $yellow;
            }
        }
    }
}
// .pi 
.pi{
    cursor: pointer;
}
// .p-badge
.p-badge{
    height: 1rem;
    min-width: 1rem;
    line-height: 1rem;
    background: $error;
    font-size: 10px;
}
// grid
.spacing-24{
    margin: -12px;
    .col-12{
        padding: 12px;
    }
}

// modal
.p-dialog{    
    .p-dialog-content{
        padding: 24px 32px 24px 32px;
        border-radius: 0.75rem;
        overscroll-behavior: none;
        -webkit-overflow-scrolling: touch;
        scrollbar-color: $inside $outline;
        scrollbar-width: thin;
        &::-webkit-scrollbar {
            width: 4px;
            height: 4px;
            background-color: $outline;
            border-radius: 2px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $inside;
            border-radius: 2px;
        }
        &::-webkit-scrollbar-track {
            border: 0.0625rem solid $outline;
            border-radius: 2px;
        }
        @media (max-width: 767px) {
            padding: 24px 20px 24px 20px;
        }
        .icon48{
            width: 48px;
            height: 48px;
            margin-bottom: 8px;
            display: block;
        }
        .forgot-password{
            text-align: right;
            position: relative;
            z-index: 2;
            span{
                cursor: pointer;
            }
        }
        .mt-30{
            margin-top: 30px;
        }
        .with-account-div{
            @include p2-paragraph($text, $family: "OpenSans-Regular");
            width: 100%;
            position: relative;
            margin: 15px 0px;
            text-align: center;
            &::after{
                content: '';
                position: absolute;
                top: 50%;
                left: 0%;
                right: 0%;
                width: calc(100% - 0px);
                height: 1px;
                background: $outline;
            }
            span{
                background: $white;
                padding: 0px 10px;
                position: relative;
                z-index: 2;
            }
        }
        .login-with{
            display: flex;
            align-items: center;
            justify-content: center;
            .login-with-item{
                background: transparent;
                border-radius: 4px;
                border: 1px solid #dadce0;
                color: #3c4043;
                height: 40px;
                padding: 14px  24px 14px 6px;
                width: 148px;
                display: flex;
                justify-content: center ;
                margin: 0px 8px;
                @media (max-width: 767px) {
                    margin: 0px 3px;
                }
                .icon24{
                    margin: 0px 14px;
                }
            }
        }
        .error-parent{
            margin-bottom: 16px;
            .p3{
                display: flex;
                align-items: center;
                margin-bottom: 6px;
                .pi{
                    font-size: 12px;
                    color: $gray;
                }
                span{
                    margin: 0px 10px;
                    color: $gray;
                }
                &.error{
                    .pi,span{
                        color: $error;
                    }
                }
                &.success{
                    .pi,span{
                        color: $cta2;
                    }
                }
            }
        }
    }
    .modal-close{
        position: absolute;
        top: 10px;
        right: 10px;
        padding: 0;
        margin: 0;
        border: none;
        background: transparent;
        outline: none;
        cursor: pointer;
    }
    .modal-end-btn{
        display: flex;
        justify-content: flex-end;
        margin-top: 24px;
    }
    &.dialog-407{
        max-width: 407px;
        width: 100%;
    }
    &.dialog-520{
        max-width: 520px;
        width: 100%;
    }
    &.dialog-492{
        max-width: 492px;
        width: 100%;
    }
    &.dialog-340{
        max-width: 340px;
        width: 100%;
        border-radius: 28px;
        .p-dialog-content{
            padding: 24px 24px 24px 24px;
            border-radius: 28px;
        }
    }
    &.apply-coupon{
        .p-dialog-content{
            padding: 0px 0px 0px 0px;
            width: 610px;
            max-width: 610px;
            @media (max-width: 767px) {
                width: 98vw;
                max-width: 98vw;
            }
            .apply-header{
                padding: 30px;
                @media (max-width: 575px) {
                    padding: 16px 20px;
                }
            }
            .apply-gray-row{
                padding: 16px 30px;
                background: $inside;
                @media (max-width: 575px) {
                    padding: 16px 20px;
                }
            }
            .apply-body{
                padding: 4px 30px;
                max-height: 250px;
                overflow: auto;
                -webkit-overflow-scrolling: touch;
                scrollbar-color: $inside $outline;
                scrollbar-width: thin;
                @media (max-width: 575px) {
                    padding: 4px 20px;
                }
                &::-webkit-scrollbar {
                    width: 4px;
                    height: 4px;
                    background-color: $outline;
                    border-radius: 2px;
                }
                &::-webkit-scrollbar-thumb {
                    background-color: $inside;
                    border-radius: 2px;
                }
                &::-webkit-scrollbar-track {
                    border: 0.0625rem solid $outline;
                    border-radius: 2px;
                }
                .coupon-rows{
                    position: relative;
                    border-bottom: 1px solid $outline;
                    padding: 16px 0px;
                }
                .dashed-btn{
                    min-width: 120px;
                    background: $white;
                    border: 1px dashed $gray;
                    color: $text;
                    padding: 9px 20px;
                    border-radius: 4px;
                    font-size: 14px;
                    font-family: 'OpenSans-Regular';
                    display: inline-block;
                    margin: 0px 16px;
                    text-align: center;
                }
                .green-text{
                    @include p2-paragraph($pink, $family: "OpenSans-Regular");
                }
            }
            .apply-footer{
                padding: 24px 30px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                box-shadow: 0px 0px 4px 0px #0000001A;

                @media (max-width: 575px) {
                    padding: 16px 20px;
                }
                .flex{
                    min-width: 200px;
                }
                .p2{
                    font-weight: 700;
                    color: $text;
                    margin: 2px 0px;
                    &.gray{
                        color: $gray;
                    }                    
                }
            }
        }
    }    
    .inner-scroll-modal{
        height: 100%;
        max-height: calc(100vh - 300px);
        overflow-y: auto;
        overflow-x: hidden;
        position: relative;
        padding-top: 10px;
        margin-top: -10px;
        // -webkit-overflow-scrolling: touch !important;
        scrollbar-color: $inside $outline;
        scrollbar-width: none;
        @media (min-height: 800px) {
            max-height: calc(100vh - 290px);
        }
        @media (max-width: 767px) {
            max-height: calc(100vh - 300px);
        }
        &::-webkit-scrollbar {
            width: 0px;
            height: 0px;
            background-color: $outline;
            border-radius: 0px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $inside;
            border-radius: 0px;
        }
        &::-webkit-scrollbar-track {
            border: 0.0625rem solid $outline;
            border-radius: 0px;
        }
    }
}

// breadcrumb
.breadcrumb {
    padding-top: 16px;
    ul{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        list-style: none;
        padding: 0px 0px;
        li{
            position: relative;
            display: flex;
            align-items: center;
            font-size: 14px;
            @media (max-width: 767px) {
                font-size: 12px;
            }
            a{
                color: var(--gray);
                font-size: 14px;
                font-family: 'OpenSans-Regular';
                font-weight: 400;
                text-decoration: none;
                @media (max-width: 767px) {
                    font-size: 12px;
                }
                &:hover{
                    text-decoration: none;
                }
            }
            span{
                color: var(--black);
                font-size: 14px;
                @media (max-width: 767px) {
                    font-size: 12px;
                }
            }
            &:not(:last-child){
                &:after{
                    content: '';
                    background: url('../images/structure/arrow-pagination.svg') no-repeat;
                    background-size: contain;
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    margin: 0 5px;
                    color: var(--gray);
                    @media (max-width: 767px) {
                        width: 18px;
                        height: 18px;
                        margin: 0 2px;
                    }
                }
            }
        }        
    }
}

// language-menu
.language-menu{
    max-width: 120px;
    .p-menuitem-link{
        padding: 5px 10px;
        .p-menuitem-text{
            span{
                @include p3-paragraph($text, $family: "OpenSans-Regular");
                display: flex;
                align-items: center;
                .language-img{
                    width: 20px;
                    height: 20px;
                    margin: 0px 8px;
                }
            }
        }
    }
}
// tool-dropdown
.tool-dropdown{
    width: fit-content;
    .p-menuitem-link{
        padding: 7px 15px;
        .p-menuitem-text{
            @include p3-paragraph($text, $family: "OpenSans-Regular");
        }
    }
}


.prime-height58{
    height: 58px;
    width: 100%;
    &.p-inputwrapper-focus{
        border-color:$yellow;
        &:focus,&:enabled:hover,&.p-focus{
            border-color:$yellow !important;
        }
    }
}
.p-password{
    width: 100%;
}
.p-float-label {
    &>label {
        left: 0.75rem;
        right: auto;
        color: $gray;
        padding: 0px 3px;
        .req-star{
            color: $error;
        }
    }
    .p-inputwrapper-filled~label,
    .p-inputwrapper-focus~label {
        left: 0.75rem;
        right: auto;
        top: 0.25rem;
        font-size: 12px;
        width: auto;
        background: white;
        padding: 0 4px;
        color: $yellow;
    }
    
    & input:focus ~ label, 
    & textarea:focus ~ label, 
    & .p-inputwrapper-focus ~ label{
        color: $yellow ;
        top: 2px;
        left: 0.7rem;
        background: $white;
    }

    
    & input:-webkit-autofill ~ label, 
    & input.p-filled ~ label, 
    & textarea.p-filled ~ label, 
    & .p-inputwrapper-filled ~ label{
        color: $gray;
        top: 2px;
        left: 0.7rem;
        background: $white;
    }
    .p-dropdown {
        width: 100%;
        min-height: 56px;
        border-color: var(--gray) !important;
        border-radius: 8px !important;
        &:focus,
        &.p-focus {
            border-width: 2px;
            border-color: var(--yellow) !important;
            box-shadow: none !important;
            outline: none !important;
        }
        .p-inputtext{
            height: 54px !important;
            padding: 15px 16px;
            opacity: 1;
        }
        // .p-dropdown-trigger {
        //     width: 2rem;
        //     .p-dropdown-trigger-icon {
        //         border: 3px solid transparent;
        //         border-bottom: 3px solid;
        //         border-left: 3px solid;
        //         transform: rotate(-45deg) scale(1.18);
        //     }
        // }
    }
    .otp-input-text{
        @include p3-paragraph($yellow, $family: "OpenSans-SemiBold");
        text-transform: uppercase;
        position: absolute;
        right: 0.75rem;
        top: 50%;
        transform: translateY(-50%);
        z-index: 2;
        cursor: pointer;
        .p-button{
            padding: 0px;
            border: none;
            background: transparent;
            padding: 0px;
            &:focus,&:hover,&:active{
                outline: none;
                border: none;
                background: transparent;
            }
        }
    }
    .search-new-input{
        height: 56px;
        [aria-atomic="false"]+div{
            border: 1px solid #959595 !important;
            border-radius: 4px;
            outline: none;
            box-shadow: none !important;
            height: 56px !important;
            width: 100%;
            font-family: "OpenSans-Regular";
            color: #12262F;
        }
        #react-select-2-listbox,#react-select-3-listbox,#react-select-4-listbox,#react-select-5-listbox{
            border:1px solid var(--gray) !important;
            border-radius: 4px;
            transform: translateY(-8px) !important;
        }
    }
}
.p-inputtext{
    border: 1px solid $gray;
    border-radius: 4px;
    outline: none;
    box-shadow: none !important;
    height: 56px;
    width: 100%;
    padding: 12px 16px;
    font-family: 'OpenSans-Regular';
    color: $text;
    &:focus,&:enabled:hover,&.p-focus{
        border-color:$yellow !important;
        label{
            color: $yellow !important;
            background: $white;
        }
    }    
    &:read-only{
        opacity: 0.5;
    }
    &:read-only~*~label,&:read-only~label{
        color:rgba($color: $gray, $alpha: .50) !important;
    }
    &.p-inputtextarea{
        height: auto;
    }
}
.p-inputnumber{
    width: 100%;
}
// PhoneInput
.PhoneInput{
    border: 1px solid #959595;
    border-radius: 4px;
    outline: none;
    box-shadow: none !important;
    height: 56px;
    width: 100%;
    padding: 12px 16px;
    direction: ltr;
    font-family: 'OpenSans-Regular';
    &:hover{
        border-color:$yellow !important;
    }
    .PhoneInputCountry{
        margin-right: 8px;
        font-family: 'OpenSans-Regular';
    }
    .PhoneInputInput{
        border: none;
        outline: none;
        box-shadow: none !important;
        font-family: 'OpenSans-Regular';
        font-size: 14px;
        color: $text;
    }
}

// checkbox
label.check-label{
    @include p2-paragraph($text);
    span{
        color: $yellow;
        cursor: pointer;
    }
}
.p-checkbox {
    width: 18px;height: 18px;
    .p-checkbox-box{
        width: 18px;
        height: 18px;
        border: 1px solid $text;
        border-radius: 4px;
        box-shadow: none !important;
        &.p-highlight{
            background: $gradient !important;
            border: 1px solid $yellow !important;
            box-shadow: none !important;
        }
        .p-checkbox-icon{
            font-size: 12px;
        }
    }
    
}
// radio
.p-radiobutton{
    .p-radiobutton-box{
        box-shadow: none !important;
        &:hover{            
            border-color: $pink !important;
        }
        &.p-highlight{
            background: transparent !important;
            border-color: $pink !important;
            .p-radiobutton-icon{
                background-color: $pink !important;
            }
        }
    }
    &.p-radiobutton-checked{
        &~label.w-full.p2{
            font-family: 'OpenSans-SemiBold';
            .p2{
                font-family: 'OpenSans-SemiBold';
            }
        }
    }
}


.p-datepicker{
    min-width: 352px !important;
    width: 352px !important;
    .p-datepicker-header {
        .p-datepicker-title,.p-link{
            color: $text !important;
        }
    }
    .p-highlight{
        background-color: $yellow !important;
        color: white !important;
        border: none !important;
        box-shadow: none !important;
        outline: none !important;
    }
    table {
        td{
            padding: 0.25em !important;
            .p-highlight{
                background-color: $yellow !important;
                color: white !important;
            }
        }
    }
}
// divider
.p-divider{
    &::before{
        border-color: $outline !important;
    }
}
// 
.input-parent{
    position: relative;
    .p-error{
        position: relative;
        font-size: 12px;
        margin-bottom: -16px;
    }
    &.mobile-input-parent{
        .mobile-input{
            position: absolute;
            font-size: 16px;
            top: 50%;
            left: 58px;
            right: auto;
            color: #959595;
            padding: 0px 3px;
            transform: translateY(-50%);
            background: #ffffff;
            transition: all 200ms ease-in-out;
            z-index: -1;
            &.label-top{
                top: -7px;
                left: 0.7rem;
                transform: translateY(0%);
                font-size: 12px;
                z-index: 2;
            }
        }
        .PhoneInput{
            &.PhoneInput--focus~.mobile-input{
                top: -7px;
                left: 0.7rem;
                transform: translateY(0%);
                font-size: 12px;
                z-index: 2;
                color: #EFB34C
            }
        }
        .otp-input-text{
           @include p3-paragraph($yellow, $family: "OpenSans-SemiBold");
            text-transform: uppercase;
            position: absolute;
            right: 0.75rem;
            top: 50%;
            transform: translateY(-50%);
            z-index: 2;
            cursor: pointer;
            .p-button{
                padding: 0px;
                border: none;
                background: transparent;
                padding: 0px;
                &:focus,&:hover,&:active{
                    outline: none;
                    border: none;
                    background: transparent;
                }
            }
        }
    }
}
.check-right{
    @include p3-paragraph($yellow);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin-top: -1px;
    right:  1rem;
    text-transform: uppercase;
    cursor: pointer;
}

.verify-otp{
    position: absolute;
    right: 0;
    bottom: -18px;
    color: #EFB34C;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    z-index: 1000;
}
@media (max-width:889px) {
    .p-tieredmenu .p-menuitem-active>.p-submenu-list{
        position: relative;
        left: 0px;
    }
}
.compare-dropdown{
    .p-dropdown-item{
        max-width: 200px;
        white-space: normal;
    }
}
.p-dropdown-items-wrapper{
    max-height: 240px !important;
    .p-dropdown-item[data-pc-section="item"]{
        font-size: 12px !important;
    }
}
.p-dropdown-panel .p-dropdown-items{
    padding: 0px 0px !important;
}
.p-galleria-item{
    max-height: 450px !important;
}
.p-toast-summary{
    text-transform: capitalize !important;
}
.p-galleria-thumbnails-left .p-galleria-thumbnail-items, .p-galleria-thumbnails-right .p-galleria-thumbnail-items {
    flex-direction: column;
    height: auto;
}
.google-search{
    &.p-inputwrapper-focus label{
        color: #959595;
        top: 2px ;
        left: 0.7rem;
        background: #FFFFFF;
        font-size: 12px;
        z-index: 1;
    }
}
.p-menu.p-menu-overlay {
    box-shadow: 0px 0px 4px 0px #00000040;
    border-radius: 2px !important;
}
.p-dropdown-empty-message{
    display: none !important;
}
[id^="react-select-"]{
    [class*=MenuList]{
        padding: 0px 0px !important;
        div{
            display: none;
            &[id^="react-select-"]{
                display: block;
            }
        }
    }
}