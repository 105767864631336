@import "../../styles.scss";

.cart-parent{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: calc(100vh - 310px);
    padding: 40px 0px 50px 0px;
    @media (max-width: 991px) {
        flex-direction: column;
        align-items: center;
    }
    .cart-left{
        width: calc(100% - 410px);
        position: relative;
        @media (max-width: 991px) {
            width: 100%;
        }
        .cart-content-row{
            display: flex;
            align-items: center;
            justify-content: space-between;
            @media (max-width: 660px) {
                flex-direction: column;
                align-items: flex-start;
            }
            .cart-column-left{
                display: flex;
                align-items: center;
                justify-content: flex-start;
                .cart-image{
                    width: 80px;
                    height: 80px;
                    cursor: pointer;
                    @media (max-width: 660px) {
                        width: 60px;
                        height: 60px;
                    }
                    img.product-image{
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
                .cart-content-text{
                    font-size: 14px;
                    padding: 0px 30px;
                    @media (max-width: 660px) {
                        padding: 0px 12px;
                    }
                    .p2{
                        overflow: hidden;
                        text-overflow: ellipsis;
                        max-width: 240px;
                        white-space: nowrap;
                        &.green{
                            color: var(--pink);
                            font-family: 'OpenSans-SemiBold';
                        }
                    }
                    .h5{
                        cursor: pointer;
                        font-size: 14px;
                        margin-bottom: 0px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        width: 100%;
                        max-width: 280px;
                        @media (max-width: 359px) {
                            max-width: 220px;
                        }
                    }
                }
            }
            .cart-column-right{
                display: flex;
                align-items: center;
                justify-content: flex-end;
                @media (max-width: 660px) {
                    width: 100%;
                }
                .relative{
                    text-align: right;
                    .h5{
                        font-size: 14px;
                        margin-bottom: 0px;
                        white-space: nowrap
                    }
                    .discount-text{
                        @include p3-paragraph($error);
                        text-decoration: line-through;
                    }
                }
                .quantity-box{
                    width: 56px;
                    height: 56px;
                    border: 1px solid $outline;
                    box-shadow: 0px 3px 4px 0px #0000000A;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0px 24px;
                    input{
                        border: none;
                        width: auto;
                        box-shadow: none;
                        max-width: 40px;
                        text-align: center;
                        &::-webkit-outer-spin-button,&::-webkit-inner-spin-button{
                            -webkit-appearance: none;
                            margin: 0;
                        }
                        &[type=number]{
                            -moz-appearance: textfield;
                        }
                        &:focus,&:active{
                            outline: none;
                        }
                    }
                    .quantity-btn{
                        cursor: pointer;
                        display: flex;
                        flex-direction: column;
                        &:focus,&:active{
                            outline: none;
                        }
                        .pi{
                            color: $gray;
                            font-size: 12px;
                            &:hover,&:focus,&:active{
                                color: $text;
                            }
                        }
                    }
                } 
                .icon24{
                    cursor: pointer;
                }
            }
        }
        .p-divider{
            margin: 16px 0px;
        }
    }
    .cart-right{
        width: 410px;
        padding-left: 50px;
        @media (max-width: 991px) {
            width: 100%;
            padding-left: 0px;
            margin-top: 40px;
        }        
        .cart-right-box{
            background: var(--inside);
            padding: 20px 20px 24px 20px;
            width: 100%;
            border-radius: 8px;
            .h6{
                font-size: 14px;
                margin-bottom: 8px;
            }
            .apply-coupon-row{
                display: flex;
                align-items: center;
                justify-content: space-between;
                .apply-coupon-column{
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    .apply-coupon-text{
                        font-size: 14px;
                        padding: 0px 8px;
                    }
                }
            }
            .price-details-row{
                display: flex;
                align-items: center;    
                justify-content: space-between; 
                .p2{
                    padding: 6px 0px;
                    &.yellow{
                        color: var(--yellow);
                    }
                    &.bold{
                        font-family: 'OpenSans-Bold';
                    }
                    .green{
                        color: var(--pink);
                    }
                }
            }
            .p-divider{
                margin: 16px 0px;
            }
        }
    }
    .checkout-heading {
        .p2-bold {
            font-family: 'OpenSans-Bold';
        }
        .gray {
            color: var(--gray);
        }
    }
    .accordion {
        .p-accordion-tab {
            margin-bottom: 20px;
        }
        .accordion-tab {
            
            .p-accordion-header-link {
                background-color: var(--outline);
                border: none;
                border-radius: 8px;
                padding: 16px 24px 16px 40px;
                .p2-semibold {
                    font-family: 'OpenSans-SemiBold' !important;
                }
                .gray {
                    color: var(--gray);
                }
                @media(max-width: 767px) {
                    padding: 16px 40px 16px 24px;
                }
            }
            
            &:not(.p-disabled) {
                &.p-highlight {
                    .p-accordion-header-link {
                        background-color: var(--outline);
                        border: none;
                        border-radius: 8px;
                    }
                }
                .p-accordion-header-link {
                    &:focus {
                        box-shadow: none;
                    }
                }
            }
            .p-accordion-content {
                padding: 0;
                border: none;
                background: #ffffff;
            }
            .accordion-panel {
                padding: 20px 40px 30px 40px;
                @media(max-width: 767px) {
                    padding: 20px 24px 30px 24px;
                }
            }
            .p-accordion-toggle-icon {
                position: absolute;
                right: 28px;
                margin-right: 0 !important;
                @media(max-width: 767px) {
                    right: 16px;
                }
            }
        }
        
    }
    .trn-no-block {
        // border: 1px solid var(--outline);
        border-radius: 8px;
        padding: 18px 40px;
        @media(max-width: 767px) {
            padding: 18px 24px;
        }
    }
    .trn-no-block-bg-gray{
        border-radius: 8px;
        padding: 18px 64px;
        background-color: var(--inside);
        @media(max-width: 767px) {
            padding: 18px 24px;
        }
    }
    .vertical-align-middle{
        min-width: 80px;
    }
}
.chips-outline {
    background-color: transparent;
    border: 1px solid var(--pink);
    border-radius: 4px;
    padding: 2px 4px;
    @include p3-paragraph($pink, 'OpenSans-SemiBold');
    margin: 0px 16px;
    display: inline-block;
    @media(max-width: 767px) {
        margin: 4px 16px;
    }
}
.p2-semibold {
    font-family: 'OpenSans-SemiBold';
}
.p2-bold {
    font-family: 'OpenSans-Bold';
}
.h-75vh{
    min-height: 75vh;
    .p-button{
        font-size: 16px !important;
        width: 186px;
    }
}