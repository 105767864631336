@import "../../styles.scss";

.home-section1{
    padding: 30px 0px 0px 0px;
    .home1-image-box{
        position: relative;
        height: 250px;
        overflow: hidden;
        width: 100%;
        border-radius: 0.25rem;
        .home1-img{
            height: 100%;
            width: 100%;
            object-fit: cover;
            transition: all 0.3s ease-in-out;
        }
        .overlay-text{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,0);
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            padding: 0px 250px 0px 52px;
            @media (max-width: 767px) {
                padding: 15px 16px 15px 16px;
            }
            .h2{
                color: $yellow;
                margin-bottom: 4px;
            }
            .p2{
                color: $outline;
                line-height: 20px;
            }
        }
        &:hover{
            .home1-img{
                transform: scale(1.05);
            }
            .overlay-text{
                .link{
                    color: $yellow;
                    .pi{
                        color: $yellow;
                        transform: translateX(5px);
                    }
                }
            }
        }
    }
    .home2-image-box{
        position: relative;
        height: 250px;
        overflow: hidden;
        width: 100%;
        border-radius: 0.25rem;
        transition: all 0.3s ease-in-out;
        .home1-img{
            height: 100%;
            width: 100%;
            object-fit: cover;
            transition: all 0.3s ease-in-out;
        }
        .overlay-text{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,0);
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex-direction: column;
            padding: 0px 0px 30px 0px;
            .h3{
                color: $white;
                margin-bottom: 14px;
            }
            .link{
                @include p2-paragraph($white) ;
                line-height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                text-decoration: none;
                position: relative;
                margin-right: -12px;
                transition: all 0.3s ease-in-out;
                color: $white !important;
                cursor: pointer;
                .pi{
                    margin: 0px 12px;
                    transition: all 0.3s ease-in-out;
                    color: $white !important;
                }     
                          
            }
            .link{
                &:hover{
                    color: $yellow !important;
                    .pi{
                        color: $yellow !important;
                    }
                } 
            }
        }
        &:hover{
            .home1-img{
                transform: scale(1.05);
            }
            .overlay-text{
                .link{
                    color: $yellow;
                    .pi{
                        color: $yellow;
                        transform: translateX(5px);
                    }
                }
            }
        }
    }
}

.home-section2{
    padding: 40px 0px;
    .heading-row{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;
        @media (max-width: 576px) {
            flex-direction: column;
            align-items: flex-start;
            .link {
                cursor: pointer;
                margin-top: 8px;
                .link-content{
                    margin: 0px 0px 0px 0px !important;
                }
            }
        }
    }
    .product-row{
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 12px;
        @media (max-width: 992px) {
            grid-template-columns: repeat(3, 1fr);
        }
        @media (max-width: 767px) {
            grid-template-columns: repeat(1, 1fr);
        }
        .product-box{
            min-width: 20%;
            cursor: pointer;
            @media (max-width: 992px) {
                &:nth-child(4),&:nth-child(5){
                    display: none;
                }
            }
            .product-image-box{
                position: relative;
                .chips-parent{
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;
                    gap: 4px;
                    width: 100%;
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    z-index: 4;
                }
            }
        }
    }
}

.home-section3{
    background-color: $inside;
    border: 1px solid $outline;
    border-radius: 0.25rem;
    padding: 40px 40px;
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    @media (max-width: 992px) {
        padding: 30px 20px;
    }
    @media (max-width: 767px) {
        flex-direction: column;     
    }
    .sec-3-left-text{
        max-width: 260px;
        margin-right: 30px;
        @media (max-width: 992px) {
            min-width: 160px;
            margin-right: 15px ;
        }
        @media (max-width: 767px) {
            width: 100%;
            max-width: fit-content  ;
            margin: 0px 0px 0px 0px !important;
        }
        .p2{
            line-height: 20px;
            margin-bottom: 40px;
            @media (max-width: 767px) {
                margin-bottom: 10px;
            }
        }
    
        .link{
            cursor: pointer;
            @media (max-width: 767px) {
                margin-bottom: 15px;
            }
            .link-content{
                margin-left: 0px;
            }
        }
    }
    .sec-3-row{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 20px;
        @media (max-width: 992px) {
            grid-template-columns: repeat(3, 1fr);
        }
        @media (max-width: 767px) {
            grid-template-columns: repeat(1, 1fr);
            margin: auto;
        }
        .sec-3-box{
            cursor: pointer;
            @media (max-width: 992px) {            
                width: 160px;    
                &:last-child{
                    display: none;
                }
            }
            @media (max-width: 767px) {
                width: 100%;  
            }
            .sec-3-image-box{
                height: 200px;
                width: 100%;
                background-color: $white;
                border: 1px solid $outline;
                border-radius: 0.25rem;
                overflow: hidden;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 12px;
                @media (max-width: 992px) {   
                    height: 160px;
                }
                .sec-3-img{
                    height: 100%;
                    width: 100%;
                    object-fit: scale-down;
                }
            }
            .sec-3-content{
                .h6{
                    font-size: 14px;
                    color: $cta1;
                    font-family: 'OpenSans-Bold';
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 180px;
                    width: 100%;
                    margin-bottom: 8px;
                }

            }
        }
    }
}

.home-section4{
    padding: 40px 0px 50px 0px;
    .product-row{
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 36px;
        margin-top: 30px;
        @media (max-width: 992px) {
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 16px;
        }
        @media (max-width: 767px) {
            grid-template-columns: repeat(1, 1fr);
        }
        .product-box{
            min-width: 20%;
            background-color: $inside;
            border: 1px solid $outline;
            padding: 20px 20px 18px 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            cursor: pointer;
            @media (max-width: 922px) {
                &:nth-child(5){
                    display: none;
                }
            }
            .product-image-box{
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $white;
                border: 1px solid $outline;
                border-radius: 50%;
                height: 184px;
                width: 184px;
                @media (max-width: 992px) {
                    height: 160px;
                    width: 160px;
                }
                .product-image{
                    height: calc(100% - 10px);
                    width: calc(100% - 10px);
                    object-fit: scale-down;
                }
            }
            .h6{
                font-size: 14px;
                color: $cta1;
                font-family: 'OpenSans-Bold';
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100%;
                text-align: center;
                margin-top: 16px;
            }
        }
    }
}

.home-section5{
    background-color: $cta1;
    border: 1px solid $outline;
    border-radius: 0.25rem;
    padding: 40px 20px;
    @media (max-width: 576px) {
        padding: 30px 10px;
    }
    .heading-row-center{
        text-align: center;
        .h3{
            color: $white;
            margin-bottom: 8px;
        }
        .p2{
            color: $white;
            max-width: 310px;
            margin: auto auto 40px auto;
            line-height: 20px;
        }
    }
    .sec-5-row{
        display: flex;
        align-items: flex-start;
        justify-content: center;
        .sec-5-box{
            text-align: center;
            position: relative;
            width: 280px;
            transition: all 0.3s ease;
            &:nth-child(2){
                &::after{
                    content: '';
                    position: absolute;
                    right: 0px;
                    top: 0px;
                    height: 30px;
                    width: 1px;
                    background-color: $yellow;
                    bottom: 0;
                    margin: auto;
                }
                &::before{
                    content: '';
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    height: 30px;
                    width: 1px;
                    background-color: $yellow;
                    bottom: 0;
                    margin: auto;
                }
            }
            .h6{
                font-family: 'OpenSans-SemiBold';
            }
            .sec-5-icon{
                width: 71px;
                height: 71px;
                object-fit: contain;
                margin-bottom: 14px;
                transition: all 0.3s ease;
                @media (max-width: 576px) {
                    width: 60px;
                    height: 60px;                
                    
                }
            }
            &:hover{
                .sec-5-icon{
                    transform: translateY(-5px);
                    transform-origin: center;
                }
            }
        }
    }
}

.home-section6{
    padding: 50px 0px 0px 0px;
    .heading-row{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;
        @media (max-width: 576px) {
            flex-direction: column;
            align-items: flex-start;
            .link {
                cursor: pointer;
                margin-top: 8px;
                .link-content{
                    margin: 0px 0px 0px 0px !important;
                }
            }
        }
    }
    .product-row{
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 12px;
        @media (max-width: 992px) {
            grid-template-columns: repeat(3, 1fr);
        }
        @media (max-width: 767px) {
            grid-template-columns: repeat(1, 1fr);
        }
        .product-box{
            min-width: 20%;
            cursor: pointer;
            &:nth-child(4), &:nth-child(5){
                @media (max-width: 992px) {
                    display: none;
                }
            }
            .product-image-box{
                position: relative;
            }
        }
    }
}
img.home1-img {
    object-fit: contain;
    width: 100%;
    height: 243px;
}