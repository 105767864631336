// sort By Mixin
.sortby{
    &.p-inputtext-sm{
        border: none;
        outline: none !important;
        box-shadow: none !important;
        .p-hidden-accessible{
            
        }
        .p-dropdown-label{
            @include p2-paragraph($text);
            opacity: 1;
            padding: 0 10px;
            height: 20px;
            &.p-placeholder {
                @include p2-paragraph($text);
            }
        }
        .p-dropdown-trigger{
            .p-dropdown-trigger-icon{
                color: var(--text);
            }
        }
        
    }
}
.p-dropdown-items-wrapper{
    background-color: var(--outline);
}