@import "../../styles.scss";
.return-page {
  .cart-left {
    .cart-content-row {
      padding-left: 30px;
      position: relative;
      .p-checkbox.p-component{
        position: absolute !important;
        left: 0px;
      }
      .cart-column-left {
      }
      .cart-column-right {
        .h5 {
          &.green {
            color: $cta3;
          }
        }
        .p2 {
          &.gray {
            color: $gray;
          }
        }
        .quantity-box {
          min-width: 80px;
          margin: 0px 0px !important;
        }
      }
    }
  }
  .cart-right-box {
    .cart-image {
      width: 80px;
      height: 80px;
      .product-image {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
