@import "../../../styles.scss";

.slider-wrapper {
    margin-bottom: 50px;

    .slider-box {
        .slider-img {
            width: 250px;
            height: 250px;
            // object-fit: none;
            margin: auto;
            cursor: pointer;
            object-fit: contain;
        }

        .p2 {
            @include p2-paragraph($text, $family: "OpenSans-bold");
            position: relative;
            &::after {
                content: "";
                display: inline-flex;
                width: 6px;
                height: 6px;
                border: 2px solid $text;
                border-top: none;
                border-left: none;
                margin-left: 8px;
                transform: rotate(-45deg);
                cursor: pointer;
            }
            &:hover {
                color: #E8336E;
            }
        }
    }
}

.slick-prev {
    &::before {
        content: '' !important;
        background-image: url("../../../assets/images/structure/prev-arrow.svg");
        width: 20px;
        height: 20px;
        background-size: 100% 100%;
        position: absolute;
        left: 0;
        top: 0;
    }

    left: -15px;
}

.slick-next {
    &::before {
        content: '' !important;
        background-image: url("../../../assets/images/structure/next-arrow.svg");
        width: 20px;
        height: 20px;
        background-size: 100% 100%;
        position: absolute;
        right: 0;
        top: 0;
    }

    right: -15px;
}