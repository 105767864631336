@import "../../styles.scss";

.order-detail-wrapper {
    width: 100%;
    max-width: 1110px;
    margin: auto;
    .order-detail-header{
        padding: 24px;
        border: 1px solid #EDF4F4;
        border-radius: 4px;
        .header__left{
            .h2{
                @include h2-heading($text);
            }
            .p2-semibold{
                font-family: "OpenSans-SemiBold";
            }
        }
        .header__right{
            min-height: 96px;
            .p2-semibold{
                font-family: "OpenSans-SemiBold";
                color: var(--yellow);
                cursor: pointer;
            }
        }
    }
    .order-detail-body {
        border: 1px solid var(--outline);
        border-radius: 4px;
        margin-bottom: 30px;
        .body-top {
            padding: 24px 24px;
            @media (max-width: 576px) {
                padding: 16px 16px;
            }
            .p-bold {
                font-family: "OpenSans-Bold";
            }
            .divider{
                border-bottom: 1px solid var(--outline);
                margin: 20px 0px;
                @media (max-width: 576px) {
                    margin: 16px 0px;
                }
            }
            .order-tracking-wrapper{    
                .tracking-box{
                    padding: 20px;
                    background: #FAFCFC;
                    border: 1px solid #EDF4F4;
                    border-radius: 4px;
                    position: relative;
                    z-index: 0;
                    margin-top: 16px;
                } 
                .order-tracking-block{
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
                    grid-template-rows: 1fr;
                    .order-tracking-list{
                        display:  flex;
                        flex-direction: column;
                        align-items: flex-start;
                        margin-bottom: 20px;
                        position: relative;
                        &:last-child{
                            .dot-circle{
                                &::after{
                                    display: none;
                                } 
                            }
                        }
                        &.success{
                            .dot-circle{
                                border-color: var(--cta2);
                                background-color: var(--cta2);
                                &::after{
                                    content: '';
                                    width: 100%;
                                    height: 1px;
                                    background-color: var(--cta2);
                                    margin-right: 0px;
                                    margin-top: 5px;
                                    position: absolute;
                                    z-index: -1;
                                }
                            }
                        }
                        &.finish{
                            .dot-circle{
                                border-color: var(--cta2);
                                background-color: var(--cta2);
                            }
                        }
                        .dot-circle{
                            display: flex;
                            width: 12px;
                            min-width: 12px;
                            height: 12px;
                            border-radius: 50%;
                            background-color: var(--white);
                            border: 1px solid var(--outline);
                            &::after{
                                content: '';
                                width: 100%;
                                height: 1px;
                                background-color: var(--outline);
                                margin-right: 0px;
                                margin-top: 5px;
                                position: absolute;
                                z-index: -1;
                            }
                            &.reject-cancel{
                                border-color: var(--primary);
                                background-color: var(--primary);
                            }
                        }
                        .p2{
                            @include p2-paragraph($text);
                            margin: 8px 0px 4px 0;
                            @media(max-width: 767px) {
                                font-size: 9px;
                            }
                        }
                        .p3{
                            @include p3-paragraph($text);
                            @media(max-width: 767px) {
                                font-size: 8px;
                            }
                        }
                    }
                }
            }
        }
        .body-bottom {
            background-color: var(--outline);
            border-radius: 0px 0px 4px 4px;
            padding: 20px 32px;
            @media (max-width: 576px) {
                padding: 16px 16px;
            }
            .p3 {
                color: var(--pink);
            }
        }
    }
    .order-detail-footer {
        border: 1px solid var(--outline);
        border-radius: 4px;
        padding: 18px 32px;
        @media (max-width: 576px) {
            padding: 16px 16px;
        }
        .p2-semibold {
            font-family: "OpenSans-SemiBold";
            color: #EFB34C;
        }
        .p2-bold {
            font-family: "OpenSans-Bold";
        }
    }
    .hand-on{
        cursor: pointer;
    }
}

@media (max-width: 576px) {
    .order-dtl-productLftCol{
        max-width: 100% !important;
    }
    .order-detail-wrapper .hand-on {
        cursor: pointer;
        padding-right: 10px;
        max-width: 80%;
    }
}
.custom-tooltip {
    left: calc(50% - 75px) ; /* Adjust the value based on your Tooltip's width */
    top: -10px; /* Adjust as needed */
  }
  .order-detail-accordion{
    .p-accordion-tab{
        margin-bottom: 30px;   
        .p-accordion-header-link{
            border: 1px solid var(--outline) !important;
            border-radius: 4px 4px 0px 0px !important;
            background-color: var(--outline) !important;
            .p-accordion-header-text{
                display: block;
                width: 100%;
            }
        }
        .p-accordion-content{
            border: 1px solid var(--outline) !important;
            border-radius:  0px 0px 4px 4px !important;
        }
    }
}


.order-tracking-wrapper{    
    .tracking-box{
        padding: 20px;
        background: #FAFCFC;
        border: 1px solid #EDF4F4;
        border-radius: 4px;
        position: relative;
        z-index: 0;
        margin-top: 16px;
    } 
    .order-tracking-block{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr;
        .order-tracking-list{
            display:  flex;
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 20px;
            position: relative;
            &:last-child{
                .dot-circle{
                    &::after{
                        display: none;
                    } 
                }
            }
            &.success{
                .dot-circle{
                    border-color: var(--cta2);
                    background-color: var(--cta2);
                    &::after{
                        content: '';
                        width: 100%;
                        height: 1px;
                        background-color: var(--cta2);
                        margin-right: 0px;
                        margin-top: 5px;
                        position: absolute;
                        z-index: -1;
                    }
                }
            }
            &.finish{
                .dot-circle{
                    border-color: var(--cta2);
                    background-color: var(--cta2);
                }
            }
            .dot-circle{
                display: flex;
                width: 12px;
                min-width: 12px;
                height: 12px;
                border-radius: 50%;
                background-color: var(--white);
                border: 1px solid var(--outline);
                &::after{
                    content: '';
                    width: 100%;
                    height: 1px;
                    background-color: var(--outline);
                    margin-right: 0px;
                    margin-top: 5px;
                    position: absolute;
                    z-index: -1;
                }
                &.reject-cancel{
                    border-color: var(--primary);
                    background-color: var(--primary);
                }
            }
            .p2{
                @include p2-paragraph($text);
                margin: 8px 0px 4px 0;
                @media(max-width: 767px) {
                    font-size: 9px;
                }
            }
            .p3{
                @include p3-paragraph($text);
                @media(max-width: 767px) {
                    font-size: 8px;
                }
            }
        }
    }
}