@font-face {
    font-family: 'OpenSans-Regular';
    src: url('./../fonts/OpenSans-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'OpenSans-Medium';
    src: url('./../fonts/OpenSans-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'OpenSans-SemiBold';
    src: url('./../fonts/OpenSans-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'OpenSans-Bold';
    src: url('./../fonts/OpenSans-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'OpenSans-ExtraBold';
    src: url('./../fonts/OpenSans-ExtraBold.ttf') format('truetype');
}

$h1: 48px !default;
$h2: 32px !default;
$h3: 24px !default;
$h4: 20px !default;
$h5: 18px !default;
$h6: 16px !default;
$p1: 16px !default;
$p2: 14px !default;
$p3: 12px !default;
$p4: 10px !default;

// mobile
$h1-mobile: 36px !default;
$h2-mobile: 28px !default;
$h3-mobile: 20px !default;
$h4-mobile: 18px !default;
$h5-mobile: 16px !default;
$h6-mobile: 14px !default;
$p1-mobile: 14px !default;
$p2-mobile: 12px !default;
$p3-mobile: 10px !default;
$p4-mobile: 10px !default;
