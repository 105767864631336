@import '../../../styles.scss';

.header-wrapper{
    background: $white;
    position: relative;
    z-index: 9;
    top: 0px;
    width: 100%;
    transition: ease-in-out 400ms;
   &.scrolled{
    position: fixed;
    top: 0px;
    z-index: 999;
    transform: translateY(-36px);
   }
}
.header{
    background-color: $text;
    min-height: 80px;
    display: flex;
    align-items: center;
    .down-arrow{
        filter: contrast(0) brightness(5);
    }
    .header-left{            
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .header__logo{
            width: auto;
            height: auto;
            margin: 0 0px;
            img{
                height: 34px;
                position: relative;
                bottom: -2px;
            }
        }
        .p-button{
            margin: 0px 30px;
            display: flex;
            align-items: center;
            height: 40px;
            .down-arrow{
                margin-left: 10px;
                margin-top: 5px;
                position: relative;
            }
        }
        .view-catalog-drop{
            background-color: $white;
            border: 1px solid $outline;
            position: absolute;
            top: 47px;
            left: 30px;
            z-index: 999    ;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            .catalog-menu{
                min-width: max-content;
                padding: 0px;
                margin: 0px;
                height: 250px;
                overflow: auto;
                .catalog-li{
                    list-style: none;
                    // min-height: 64px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0px 16px;
                    min-height: 64px;
                    position: unset;
                    border-bottom: 1px solid $outline;
                    
                    cursor: pointer;
                    .relative{
                        display: flex;
                        align-items: center;
                        .catalog-span{
                            margin: 0px 14px;
                            font-size: 14px;
                            font-family: 'OpenSans-Bold';
                            color: $text;
                            transition: ease-in-out 300ms;
                            max-width: 263px;
                        }
                    }
                    .arrow-icon{
                        transform: rotate(-90deg);
                    }
                    &:hover,&:active,&:focus{
                        .relative{
                            .catalog-span{
                                color: $yellow;
                            }
                        }
                    }
                    .p-tabview.p-component {
                        position: absolute;
                        left: 100%;
                        top: -1px;
                        height: 100%;
                    }
                    .p-tabview-panels {
                        height: 100%;
                        border-right: 1px solid #EDF4F4 !important;
                         border-bottom: 1px solid #EDF4F4 !important;
                         border-radius: 0 !important;
                    }
                    .catalog-tab {
                        margin-top: 0px !important;
                        // padding-top: 15px;
                    }
                }
            }
            .p-tabview-nav-container{
                display: none;
            }
            .p-tabview-panels{
                padding: 0px !important;
            }
            .catalog-tab{
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                // flex-wrap: wrap;
                max-height: 250px;
                min-width: fit-content;
                margin-top: 18px;
                overflow: auto;
                -webkit-overflow-scrolling: touch;
                scrollbar-color: #f1f1f1 transparent;
                scrollbar-width: thin;

                &::-webkit-scrollbar {
                    width: 4px;
                    height: 4px;
                    background-color: transparent;
                    border-radius: 2px;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: #f1f1f1;
                    border-radius: 2px;
                }

                &::-webkit-scrollbar-track {
                    border: 0.0625rem solid transparent;
                    border-radius: 2px;
                }
                &.no-scroll{
                    overflow: hidden;
                    padding-right: 4px;
                }
                .catalog-content{
                    height: 64px !important;
                    white-space: nowrap;
                    padding: 24px 20px;
                    text-transform: capitalize;
                    a{
                        @include p2-paragraph($text,);
                        white-space: nowrap;
                        text-decoration: none;
                    }
                }
            }
        }
    }
    .header-right{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .p-overlay-badge{
            display: flex;
            align-items: center;
            cursor: pointer;
        }
        .header__user{
            display: flex;
            align-items: center;
            margin: 0px 30px;
            cursor: pointer;
            @media (max-width: 889px) {
                margin: 0px 15px;
            }
            span{
                display: flex;
                align-items: center;
                .pi-angle-down{
                    color: $white;
                }
            }
        }
    }
    @media (max-width: 899px) {
        min-height: auto;
    }
    .header-flex{
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media (max-width:899px) {
            display: none;
            .header-left {display: none;}
            .header-center {display: none;}
            .header-right {display: none;}
            .collapsible-menu {display: none;}
        }
        
    }
    .header-flex-mobile{
        display: none;
        @media (max-width: 899px) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 5px 0px;
            .header-left {
                display: flex;
                align-items: center;
                justify-content: flex-start;
            }
            .header-center {display: none;}
            .header-right {
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }
        }
        .p-button {
            &.p-button-icon-only {
                width: 2rem;
                padding: 0.5rem 0;
                margin: 5px;
                font-size: 10px;
            }
        }
    }
}

.header-center{
    width: 100%;
    max-width: 388px;
    .p-input-icon-right{
        width: 100%;
        .p-inputtext{
            width: 100%;
            height: 40px;
        }
        .pi.pi-search{
            color: $error;
        }
    }
    &.header-center-mobile{
        display: flex;
        justify-content: center;
        padding: 10px 15px !important;
        background: #FAFCFC;
        max-width: 100%;
        .p-input-icon-right{
            width: 100%;
            .p-inputtext{
                width: 100%;
            }
        }
        @media (min-width: 900px) {
            display: none;
        }
    }
}
.collapsible-menu {
    transition: all 300ms;
    .catalog-content{
        padding: 8px 0px;
        font-size: 14px;
        color: #000000;
        cursor: pointer;
        position: relative;
        transition: all 300ms;
        white-space: nowrap;
        
        .px-2{
            padding: 0px 4px;
            position: relative;
            top: -6px;
        }
        .arrow{
            position: absolute;
            right: 0px;
            top: 10px;
            transform: rotate(-90deg);
        }
        ul{
            padding: 0px 20px 0px 20px;
            margin: 0px;
            list-style: none;
            transition: all 300ms;
            li.catalog-content{
                padding: 8px 12px;
                margin: 0px;
                list-style: none;
                font-size: 14px;
                color: #000000;
                cursor: pointer;
                position: relative;
                word-wrap: normal;
                white-space: normal;
                &::before{
                    content: '';
                    position: absolute;
                    left: 0px;
                    top: 16px;
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    background: #000000;
                    opacity: 1;
                    transition: ease-in-out 300ms;
                }
                &:first-child{
                    margin-top: 10px;
                }
            }
        }
    }
   
    .p-tree {
        padding: 0 !important;
        border: 0 !important;
        .p-tree-container {
            .p-treenode {
                padding: 0rem;
                .p-treenode-content {
                    padding: 0.5rem 0rem;
                    .p-tree-toggler:enabled:hover {
                        color: #343a40;
                        border-color: transparent;
                        background: transparent;
                    }
                    .p-tree-toggler:focus {
                        box-shadow: none;
                    }
                    &:focus {
                        box-shadow: none;
                    }
                }
            }
        }
        
    }
}
.flex-center{
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-top: 8px;
}
[dir="rtl"]{
    .collapsible-menu {
        .catalog-content {           
            padding-right: 26px;
        }
    }
    .header .header-left .view-catalog-drop .catalog-menu .catalog-li .p-tabview.p-component {
        position: absolute;
        right: 100%;
        left: auto;
        top: -1px;
        height: 250px;
        overflow: overlay;
        background: #fff;
    }
}
// li.catalog-li img.arrow-icon {
//     display: none;
// }