body, html {
    overscroll-behavior: none;
}
body{
    margin: 0;
    font-family: 'OpenSans-Regular';
    -webkit-overflow-scrolling: touch;
    scrollbar-color: #f1f1f1 transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        background-color: transparent;
        border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #f1f1f1;
        border-radius: 2px;
    }

    &::-webkit-scrollbar-track {
        border: 0.0625rem solid transparent;
        border-radius: 2px;
    }
    &.no-scroll{
        overflow: hidden;
        padding-right: 4px;
    }
}
.sweet-loading{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(0 0 0 / 16%);
    z-index: 9999;
}
.bottom-bg-box {
    background-color: var(--outline);
    border-radius: 8px;
    padding: 18px 12px;
}
.p2-semibold {
    font-family: 'OpenSans-SemiBold' !important;
}

.sweet-loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(0 0 0 / 16%);
    z-index: 9999;
  
  }
  
  .sweet-loading div {
    background: red;
  }
  .p-sidebar-mask.p-component-overlay.p-component-overlay-enter.p-sidebar-visible.p-sidebar-bottom{
    background: transparent !important;
    pointer-events: none !important;
}
.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus{
    box-shadow: none !important;
}