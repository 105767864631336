@import '../../../../styles.scss';
.top-toolbar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0px;
    height: 35px;
    background-color: $white;
    @media (max-width: 899px) {
        justify-content: flex-end;
    }
    .top-toolbar-left{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .top-toolbar-left-item{
            @include p3-paragraph($text, $family: "OpenSans-Regular");
            display: flex;
            align-items: center;
            .pi-check{
                color: $error;
                font-size: 14px;
            }
            .top-toolbar-left-item-content{
                margin: 0px 8px;
            }
            a{
                color: $text;
                font-family: 'OpenSans-Semibold';
                text-decoration: none;
            }
            &~.top-toolbar-left-item{
                margin:0px 40px;
            }
        }
        @media (max-width: 899px) {
            display: none;
        }
    }
    .top-toolbar-right{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .top-toolbar-right-item{
            position: relative;
            .language-drop{
                display: flex;
                align-items: center;
                cursor: pointer;
                height: 35px;
                margin: 0px 0px;
                .language-text{
                    span{
                        @include p3-paragraph($text, $family: "OpenSans-Regular");
                        display: flex;
                        align-items: center;
                        img{
                            width: 20px;
                            height: 20px;
                            border-radius: 50%;
                            margin: 0px 8px;
                        }
                    }
                }
                .dawnArrowBlack{
                    width: 16px;
                    height: 16px;
                    margin: 0px 0px 0px 8px;
                }
            }
            .currency-drop{
                display: flex;
                align-items: center;
                cursor: pointer;
                height: 35px;
                .currency-text{
                    @include p3-paragraph($text, $family: "OpenSans-Regular");
                    margin: 0px 8px;
                }
                .dawnArrowBlack{
                    width: 16px;
                    height: 16px;
                    margin: 0px 0px 0px 0px;
                }
            }  
            &~.top-toolbar-right-item{
                position: relative;
                margin-left: 8px;
                &::before {
                    content: "";
                    position: absolute;
                    top: 0px;
                    bottom: 0px;
                    left: 0px;
                    width: 1px;
                    height: 18px;
                    background-color: $text;
                    margin: auto;
                }
            } 
        }
    }
}
.currency-dropdown-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
}