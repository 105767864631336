@import "../../styles.scss";

.static-image-box {
    position: relative;
    height: 250px;
    overflow: hidden;
    width: 100%;
    border-radius: 0.25rem;
    margin-bottom: 30px;
    @media (max-width: 767px) {
        height: 160px;
    }
    .static-img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        transition: all 0.3s ease-in-out;
    }
    .overlay-text {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0);
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        padding: 0px 52px 0px 52px;
        @media (max-width: 767px) {
            padding: 15px 16px 15px 16px;
        }
        .h2 {
            color: $white;
            margin-bottom: 4px;
        }
        .p2 {
            color: $outline;
            line-height: 20px;
        }
    }
    &:hover {
        .static-img {
            transform: scale(1.05);
        }
        .overlay-text {
            .link {
                color: $yellow;
                .pi {
                    color: $yellow;
                    transform: translateX(5px);
                }
            }
        }
    }
}

.static-content{
    position: relative;
    min-height: calc(100vh - 975px);
    h6{
        @include h6-heading($text);
        margin-bottom: 15px;
        margin-top: 20px;
    }
    h5{
        @include h5-heading($text);
        margin-bottom: 15px;
        margin-top: 20px;
    }
    h4{
        @include h4-heading($text);
        margin-bottom: 15px;
        margin-top: 20px;
    }
    h3{
        @include h3-heading($text);
        margin-bottom: 15px;
        margin-top: 20px;
    }
    h2{
        @include h2-heading($text);
        margin-bottom: 15px;
        margin-top: 20px;
    }
    h1{
        @include h1-heading($text);
        margin-bottom: 15px;
        margin-top: 20px;
    }
    p{
        @include p2-paragraph($text);
        margin-bottom: 24px;
    }
    ul,ol{
        padding: 0px 24px;
        li{
            @include p2-paragraph($text);
            margin-bottom: 15px;
        }
    }
}

.contact-box{
    width: 100%;
    max-width: 1110px;
    position: relative;
    border-radius: 10px;
    padding: 10px;
    border: 1px solid rgba(149, 149, 149, 0.50);
    background: #FFF;
    box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.03);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 60px auto;
    @media (max-width: 991px) {
        margin: 30px auto;
    }
    @media (max-width: 767px) {
        flex-direction: column;
    }
    .contact-left{
        background: #EDF4F4;
        width: 100%;
        height: 500px;
        padding: 40px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        border-radius: 8px;
        @media (max-width: 991px) {
            height: auto;
        }
        @media (max-width: 767px) {
            padding: 20px;
            height: auto;
            
        }
        .footer-info{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin: 20px 0px;
            span.info-content{
                @include p2-paragraph($text);
                margin: 0px 8px;
            }
        }
        .social-bottom{
            .social-icon{
                width: 24px;
                height: 24px;
                object-fit: contain;
                margin: 0px 8px;
                cursor: pointer;
                transition: ease-in-out 300ms;
                &:first-child{
                    margin-left: 0px;
                }
                &:last-child{
                    margin-right: 0px;
                }
                &:hover{
                    transform: translateY(-5px);
                }
            }
        }
    }
    .contact-right{
        padding: 10px 76px 10px 50px;
        width: 100%;
        @media (max-width: 991px) {
            padding: 30px 20px 30px 20px;
        }
        @media (max-width: 767px) {
            padding: 30px 6px 30px 6px;
        }
    }
}