// text heading paragraph

.h1{
    @include h1-heading($text);
}
.h2{
    @include h2-heading($text);
}
.h3{
    @include h3-heading($text);
}
.h4{
    @include h4-heading($text);
}
.h5{
    @include h5-heading($text);
}
.h6{
    @include h6-heading($text);
}
.p1{
    @include p1-paragraph($text);
}
.p2{
    @include p2-paragraph($text);
}
.p3{
    @include p3-paragraph($text);
}
.p4{
    @include p4-paragraph($text);
}
// color
.white{
    color: var(--white);
}
.yellow{
    color: var(--yellow) !important;
}
// link
.link{
    @include p2-paragraph($yellow, $family: 'OpenSans-Semibold');
    text-decoration: none;
    display: flex;
    align-items: center;
    .link-content{
        cursor: pointer;
        margin: 0px 8px;
    }
    .pi{
        color: $yellow;
        transition: all 0.3s ease-in-out;
    }  
    &:hover{
        .pi{
            transform: translateX(5px);
        }
    }
}
.underline-none{
    text-decoration: none;
    @include p2-paragraph($error, $family: 'OpenSans-Regular');
}
// read-more
.read-more{
    @include p2-paragraph($error, $family: 'OpenSans-Semibold');
    text-decoration: none;
    cursor: pointer;
}

// body[style~="padding-left:17px"]{
    
// }
.container{
    width: 100%;
    max-width: 1266px !important;
    margin: auto;
    @media (max-width: 1299px) {
        padding: 0px 16px;
    }
}
.icon24{
    width: 24px;
    height: 24px;
    object-fit: contain;
}
// toolbar sticky
.toolbar-sticky{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: $outline;
    height: auto;
    width: 100%;
    transition: ease-in-out 600ms;
    &.scrolled{
        position: fixed;
        top: 78px;
        z-index: 9;
    }
    .toolbar-content{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 4px 0;
        .toolbar-link{
            cursor: pointer;
            display: flex !important;
            align-items: center;
            text-decoration: none;
            padding: 8px 18px;
            max-width: 150px;
            min-width: auto;
            .icon24{
                filter: contrast(1) brightness(1);
                object-fit: cover;
            }
            .tool-content{
                @include p2-paragraph($text);
                margin: 0px 8px;
            }
            &:focus,&:active,&.active{
                .icon24{
                    filter: contrast(1) brightness(1);
                }
                .tool-content{
                    color: $error;
                }
            }   
        }
        .tool-content {
            white-space: unset;
        }
    }
    @media(max-width: 899px) {
        display: none;
    }
}
// .header-wrapper.scrolled-down ~ .toolbar-sticky{
//     position: sticky;
//     top: 0px;
//     transform: translateY(70px);
//     width: 100%;
// }
.header__user-drop{
    transform: translateY(28px) !important;
    background: $inside !important;
    border-radius: 0px;
    @media (max-width: 899px) {
        transform: translateY(14px) !important;
    }
    .p-menuitem{
        border-bottom: 1px solid $outline;
        &:last-child{
            border-bottom: none;
        }
        .p-menuitem-link{
            box-shadow: none !important;
            transition: all 200ms;
            
            .p-menuitem-icon{
                color: $text;
                transition: all 200ms;
            }
            .p-menuitem-text{
                color: $text;
                transition: all 200ms;
                font-size: 14px;
            }
            &:hover,&:focus,&:active{
                background-color: transparent !important;
                .p-menuitem-icon,.p-menuitem-text{
                    color: $yellow;
                }
            }
        }
        &.p-menuitem-active{
            .p-menuitem-link{
                background-color: transparent !important;
                .p-menuitem-icon,.p-menuitem-text{
                    color: $text;
                }
            }
        }
        .p-submenu-list-flipped,.p-submenu-list{
            background: $inside !important;
            .p-menuitem{
                .p-menuitem-link{
                    padding: 8px 16px;
                    .p-menuitem-text{
                        color: $text;
                        transition: all 200ms;
                        .language-items{
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            .icon20{
                                width: 20px;
                            }
                            span{
                                margin: 0px 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}

// chips
.chips{
    @include p3-paragraph($white, $family: 'OpenSans-Semibold');
    height: 24px;
    padding: 4px 8px;
    border-radius: 8px;
    &.error{
        background-color: $error;
    }
    &.warning{
        background-color: var(--yellow);
    }
}

.chips-status{
    padding: 4px 14px;
    border-radius: 6px;
    display: inline-block;
    text-transform: uppercase;
    font-size: 12px !important;
    font-family: 'PublicSans-Medium' !important;
    font-weight:500;
    &.error{
        background-color: $error;
    }
    &.success{
        background: #EFF8EB;
        color: #59AA00;
    }
    &.danger{
        background: #FCF3F2;
        color: #D23D50;
    }
    &.delivered{
        background: #EFF8EB;
        color: #59AA00;
    }
    &.canceled{
        background: #f7ccc8;
        color: #cb1f35;
    }
    &.cancelled{
        background: #f7ccc8;
        color: #cb1f35;
    }
    &
    &.pending{
        background: #c9d7f7;
        color: #5e548e;
    }
}
.product-chips-status{
    padding: 4px 14px;
    border-radius: 6px;
    display: inline-block;
    font-size: 12px !important;
    text-transform: capitalize;
    font-weight:500;
    &.confirmed{
        background: #EFF8EB;
        color: #59AA00;
    }
    &.canceled{
        background: #f7ccc8;
        color: #cb1f35;
    }
    &.cancelled{
        background: #f7ccc8;
        color: #cb1f35;
    }
    &.shipped{
        background: #FFF6ED;
        color: #EE9C16;
    }
    &.pickup{
        background: #EFF8EB;
        color: #59AA00;
    }
    &.delivered{
        background: #EFF8EB;
        color: #59AA00;
    }
    &.returned{
        background: #fddeda;
        color: #ad2a3a;
    }
}

// product box
.product-box{
    transition: all 300ms;
    cursor: pointer;
    .product-image-box{
        width: 100%;
        height: 243px;
        background-color: $white;
        border-radius: 8px;
        overflow: hidden;
        display: flex;  
        align-items: center;
        justify-content: center;
        position: relative;
        .product-image{
            width: 100%;
            height: 100%;
            object-fit: scale-down;
            transition: all 300ms;
            transform: scale(1);
            transform-origin: center center;
        }
        .add-to-card-btn{
            height: 54px;
            width: calc(100% - 40px);
            position: absolute;
            bottom: -55px;
            visibility: hidden;
            opacity: 0;
            background-color: $cta2;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 300ms;
            @media (max-width: 899px) {
                bottom: 12px;
                visibility: visible;
                opacity: 1;
            }
        }
    }
    .product-content{
        padding: 20px 0px;
        .p3{
            @include p3-paragraph($pink, $family: 'OpenSans-Semibold');
            margin-bottom: 8px;
            &.gray{
                @include p3-paragraph($gray, $family: 'OpenSans-Regular');
                margin-bottom: 10px;
            }
        }
        .h6{
            @include h6-heading($text, $family: 'OpenSans-bold');   
            margin-bottom: 8px;
            font-size: 14px;
            white-space: nowrap;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .description{
            @include p3-paragraph($gray, $family: 'OpenSans-Regular');
            margin-bottom: 8px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;  
            overflow: hidden;
        }
        .price-row{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .p2{
                @include p2-paragraph($text, $family: 'OpenSans-bold');
                margin-bottom: 0px;
            }
            .p3{
                @include p3-paragraph($error, $family: 'OpenSans-Regular');
                margin: 0px 8px;
            }
            .cross{
                text-decoration: line-through;
            }
        }
    }
    &:hover{
        .product-image-box{
            .product-image{
                transform: translateY(-2px);
            }
            .add-to-card-btn{
                visibility: visible;
                opacity: 1;
                bottom: 12px;
            }
        }
    }
}

// heading row
.heading-row{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    @media (max-width: 576px) {
        flex-direction: column;
        align-items: flex-start;
        .link {
            margin-top: 8px;
            .link-content{
                margin: 0px 0px 0px 0px !important;
            }
        }
    }
}

//first letter capitalize
.capitalize-first-letter{
    text-transform: capitalize;
}
.max-w-500{
    width: 100%;
    max-width: 500px;
}
.topbarStickyBox-nw{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .toolbar-link {
        padding-right: 35px;
        align-items: center;
        display: flex;
        margin: 10px 0;
        cursor: pointer;
        @include p2-paragraph($text);
        text-decoration: none;
        .icon24{
            margin-right: 10px;
        }
    }
}
.otpbox-p input.p-inputtext {
    padding-right: 112px;
}
body[dir="rtl"] .p-password-input {
    padding-right: 40px !important;
}
[dir=rtl] .p-float-label label {
    right: 0.7rem;
    left: auto !important;
}
[dir="rtl"]{
    .comapareProductCol{
        .pl-3 {
            padding-left: 0 !important;
            padding-right: 10px;
        }
    }
    .otpbox-p input.p-inputtext{
        padding-left: 65px !important;
        padding-right: 16px;
    }
}
// .order-dtl-productLftCol{
//     max-width: 50%;
// }
.order-dtl-productRgtCol{
    max-width: 50%;
}

// .showCategoriesBtn{
//     position: relative;
// }
// .viewCategoryOper{
//     display: none !important;
// }
// .showCategoriesBtn:hover .viewCategoryOper {
//     display: block !important;
//     position: absolute;
//     margin-top: -9px;
// }
// .subcategoryTabs{
//     display: none;
// }
// .catalog-li:hover .subcategoryTabs{
//     display: flex !important;
// }

.footer-wrapper {
    margin-top: 80px;
}
.compareproductshow-btn{
    border: 19px solid transparent !important;
    border-bottom: 18px solid rgba(0, 0, 0, 0.7) !important;
    height: 20px !important;
    width: 76px !important;
    position: fixed !important;
    padding: 0 !important;
    bottom: 0 !important;
    right: 40px !important;
    top: auto;
    z-index: 1;
    background: transparent;
    cursor: pointer;
    &::after{
        border: solid #fff;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(-135deg);
        content: "";
        position: absolute;
        left: 15px;
        top: 7px;
    }
}
.addButton-no-item{
    background: #ccc !important;
    &:hover{
        background: #ccc !important;
    }
}
.p-dropdown:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
    border-color: #F9B000 !important;
}
button.p-galleria-item-prev.p-galleria-item-nav.p-link.p-disabled {
    display: none;
}   
button.p-galleria-item-next.p-galleria-item-nav.p-link.p-disabled {
    display: none;
}
.p-dropdown:not(.p-disabled):hover {
    border-color: #F9B000 !important;
}

.p-toast .p-toast-message.p-toast-message-success {
    background: #4CAF50;
    border: solid #1ea97c;
    border-width: 0;
    color: #1ea97c;
    .p-toast-message-content {
        // display: flex;
        // align-items: center;
        .p-toast-message-icon.p-icon {
            width: 26px;
            height: auto;
            color: #fff;
        }
        .p-toast-detail {
            font-size: 14px;
            margin: 0 0 0 0px !important;
            color: #fff;
        }
        .p-toast-summary {
            color: #fff;
            font-size: 14px;
        }
    }
    
    .p-toast-icon-close{
        color: #fff !important;
    }
}
.p-toast {
    opacity: 1;
    .p-toast-message-text{
        .p-toast-summary{
            display: none !important;
        }
    }
}
.p-toast-top-right {
    right: 0 !important;
    left: 0 !important;
    margin: 0 auto !important;
}
.cashOnDelivery svg.p-icon.p-accordion-toggle-icon {
    display: none;
}
@media (max-width: 576px) {
    .order-dtl-productRgtCol {
        max-width: 100%;
        /* margin-left: auto; */
        margin-left: 0;
        text-align: left !important;
        padding-left: 72px;
    }
    .order-detail-wrapper .order-detail-body .body-top .order-tracking-wrapper .order-tracking-block {
        display: flex;
    }
    .order-detail-wrapper .order-detail-body .body-top .order-tracking-wrapper .order-tracking-block .order-tracking-list {
        padding-right: 10px;
    }
    iframe#paymentFrame {
        height: 306px;
        max-height: 100%;
    }
}

.slider-box:focus-visible {
    outline: none !important;
}
.p-radiobutton + label.ml-2 {
    font-family: 'OpenSans-Regular';
    font-size: 14px;
}
.quantity-box{
    @media (max-width: 767px) {
        .quantity-btn .pi {
            font-size: 24px !important;
        }
    }
}
@media (max-width: 767px) {
    .cart-parent .cart-left .cart-content-row .cart-column-left .cart-content-text .p2 {
        max-width: 110px;
        width: 110px;
    }
    iframe[width="482"] {
        width: 100% !important;
    }
    .p-dialog.p-component[style="width: 60vw; height: 80vh;"] {
        width: 92% !important;
    }
    .p-dialog.p-component.light-box.p-dialog-default.p-ripple-disabled.p-dialog-enter-done {
        width: 92% !important;
    }
    .cart-parent .cart-left .cart-content-row .cart-column-right .quantity-box {
        width: 70px;
    }
    .bundle-detail-section1 .quantity-row .quantity-box input {
        font-size: 24px;
    }
    .detail-section1 .information-box__row__title {
        min-width: 100%;
        max-width: 100%;
    }
    .detail-section1 .share-compare-row .wholesale-box .wholesale-tool-box {
        left: 0px !important;
        right: -35px !important;
        width: 250px !important;
        margin: auto !important;
    }
    .quantity-box input[type="number"] {
        font-size: 22px;
    }
}
.terms-and-conditions{
    text-align: justify;
    padding-right: 10px;
}
@media (min-width: 768px) and (max-width: 991px){
    .home-section1 {
        .home1-image-box {
            .overlay-text {
                padding: 0px 20px 0px 30px !important;
            }
        }
    }
    .p-dialog.p-component[style="width: 60vw; height: 80vh;"] {
        width: 92% !important;
    }
    .scrolled-down .top-boxes {
        top: 52px;
    }
    .detail-section1 .share-compare-row .wholesale-box .wholesale-tool-box {
        left: 0px !important;
        right: -35px !important;
        width: 350px !important;
        margin: auto !important;
    }
}
@media (min-width: 992px) and (max-width: 1200px){
    .scrolled-down .top-boxes {
        top: 150px;
    }
    .detail-section1 .share-compare-row .wholesale-box .wholesale-tool-box {
        left: 0px !important;
        right: -35px !important;
        width: 350px !important;
        margin: auto !important;
    }
    .right-side .relative {
        display: flex;
        align-items: center;
    }
    button.p-button.p-component.p-button-lg {
        padding: 0 10px;
    }
}
.detail-section1 .quantity-row .quantity-box input{
    max-width: 40px;
    padding: 0px;
    height: calc(100% - 4px);
}
.p-bold {
    font-family: "OpenSans-Bold";
}
.p-semibold{
    font-family: "OpenSans-Semibold";
}
.p-datatable.detail-table {
    .p-datatable-thead > tr > th{
        background: transparent !important;
        &.align-center{
            text-align: center;
            .p-column-header-content{
                justify-content: center;
            }
        }
        .p-column-title{
            font-size: 14px !important;
        }
        span[data-pc-section="sort"]{
            transform: scale(0.75);
        }
    }
    .p-datatable-tbody > tr > td{
        font-size: 14px !important;
        &.align-center{
            text-align: center;
        }
    }
}
.custom-tooltip{
&.p-tooltip {
    .p-tooltip-arrow{
        border-right-color: #E93D3D !important; 
    }
.p-tooltip-text {
        background: #E93D3D;
        color: #ffffff;
        font-size: 12px;
        padding: 0.75rem 0.75rem;
        box-shadow: none;
        border-radius: 8px;
        max-width: 290px ;
    } 
}
}
.gray-out-content{
    filter: contrast(0);
}
.after-checked.gray-out-content{
    filter: contrast(0.5);
}