@import "../../../styles.scss";
.news-latter{
    background: url('../../../assets/images/structure/newsletter-bg.jpg') no-repeat;
    background-size: cover;
    background-position: center;
    padding: 50px 56px;
    height: 193px;
    width: 100%;
    max-width: 1266px;
    margin: 40px auto;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    @media (max-width: 992px) {
        padding: 30px 16px;
    }
    @media (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start;
    }
    .h3{
        @include h3-heading($text);
        margin-bottom: 10px;
        span{
            color: $yellow;
        }
    }
    .new-latter-input{
        position: relative;
        @media (max-width: 767px) {
            width: 100%;
        }
        .p-inputtext{
            height: 56px;
            width: 514px;
            background-color: transparent;
            padding-right: 130px;
            @media (max-width: 992px) {
                width: 370px;
            }
            @media (max-width: 767px) {
                width: 100%;
            }
        }
        .p-button{
            position: absolute;
            top: 9px;
            right: 9px;
            height: 38px;
            // background: $cta2;
        }
    }
}
.footer-wrapper{
    .h6{
        @include h6-heading($text);
        margin-bottom: 15px;
    }
    .p2{
        @include p2-paragraph($text);
        margin-bottom: 15px;
    }
    .footer-info{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 10px;
        span.info-content{
            @include p2-paragraph($text);
            margin: 0px 8px;
        }
    }
    .footer-link{
        @include p2-paragraph($text);
        margin-bottom: 15px;
        display: table;
        text-decoration: none;
        position: relative;
        &::after{
            content: '';
            position: absolute;
            bottom: -5px;
            left: 0px;
            right: 0px;
            width: 0%;
            height: 2px;
            background-color: $error;
            transform-origin: center;
            transition: 300ms ease-in-out;
        }
        &:hover{
            &::after{
                width: 100%;
            }
        }
    }
    .right-side{
        display: inline-block;
        position: relative;
        clear: both;
        float: right;
        @media (max-width: 992px) {
            float: left;
        }
    }
    .social-icon{
        width: 24px;
        height: 24px;
        object-fit: contain;
        margin: 0px 8px;
        cursor: pointer;
        transition: ease-in-out 300ms;
        &:first-child{
            margin-left: 0px;
        }
        &:last-child{
            margin-right: 0px;
        }
        &:hover{
            transform: translateY(-5px);
        }
    }
}
.footer-bottom{
    background-color: $text;
    height: 61px;
    display: flex;
    align-items: center;
    @media (max-width: 767px) {
        padding: 10px 0px;
        height: auto;
        text-align: center;
    }
    .footer-bottom-row{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;        
        @media (max-width: 767px) {
            flex-direction: column;
            justify-content: center;
        }
        .footer-bottom-left{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            @media (max-width: 767px) {
                flex-direction: column;
                justify-content: center;
            }
            .logo-icon{
                width: auto;
                height: 30px;
                margin: 0 0px;
            }
            .copyright-text{
                @include p2-paragraph($white);
                margin: 0px 20px;
                @media (max-width: 767px) {
                    margin: 15px 0px;
                }
            }
        }
        .footer-bottom-right{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .currency-icon{
                margin-left: 8px;
                width: 36px;
                height: 22px;
                object-fit: contain;
            }
        }
    }
}
.footer-wrapper {
    margin-top: 80px;
}