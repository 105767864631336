[dir="rtl"] {
    .breadcrumb ul li:not(:last-child):after{
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
    }
    // slider arrow
    .slick-next{
        right: -25px;
        left: auto !important;
    }
    .slick-prev{
        right: auto !important;
        left: -25px;
    }
    .link{
        .pi{
            transform: rotate(180deg) !important;
        }
        &:hover{
            .pi{
                transform: rotate(180deg) translateX(5px) !important;
            }
        }
    }
    .top-toolbar .top-toolbar-right .top-toolbar-right-item ~ .top-toolbar-right-item{
        margin-left: 0px;
        margin-right: 8px;
        &::before{
            left: auto !important;
            right: 0px ;
        }
    }
    .language-drop{
        .dawnArrowBlack{
            margin: 0px 8px 0px 0px !important;
        }
    }
    .header{
        .header-flex .header-left .p-button .down-arrow{
            margin-right: 10px;
            margin-left: 0px !important;
        }
        .view-catalog-drop{
            right: 30px;
            left: auto !important;
            .catalog-menu .catalog-li .arrow-icon{
                -webkit-transform: rotate(90deg) !important;
                transform: rotate(90deg) !important;
            }
        }
        .header-flex-mobile .p-button.p-button-icon-only{
            transform: rotate(180deg);
        }

    }
    .header-checkout .card-payment-tab .option{
        padding: 0px 0px 0px 10px;
        @media (max-width: 576px) {
            padding: 0px 0px 0px 0px;
        }
    }
    // inputs
    .p-float-label { 
        label{
            right: 0.75rem;
            left: auto !important;
        }
        & input:focus ~ label, 
        & textarea:focus ~ label, 
        & .p-inputwrapper-focus ~ label{
            right: 0.75rem;
            left: auto !important;
        }
        .otp-input-text{
            right: auto !important;
            left: 0.75rem;
        }
        .passwordIcon {
            right: auto;
            left: 12px;
        }
    }
    .p-inputwrapper-filled~label,
    .p-inputwrapper-focus~label {
        right: 0.75rem;
        left: auto !important;
    }
    .p-float-label input:-webkit-autofill ~ label, 
    .p-float-label input.p-filled ~ label, 
    .p-float-label textarea.p-filled ~ label, 
    .p-float-label .p-inputwrapper-filled ~ label{
        right: 0.75rem;
        left: auto !important;
    }
    .p-input-icon-right {
        .pi {
            left: 0.75rem;
            right: auto !important;
        }
        .p-inputtext{
            padding-left: 2.5rem;
            padding-right: 1rem;
        }
    }
    .home-section1 .home1-image-box .overlay-text{
        padding: 0 52px 0 250px;
        @media (max-width: 767px) {
            padding: 15px 16px 15px 16px;
        }
    }
    .home-section3 .sec-3-left-text {
        margin-right: 0px;
        margin-left: 30px;
        .link .link-content{
            margin: 0px 0px 0px 8px;
        }
    }
    // new-latter-input
    .new-latter-input{
        .p-inputtext{
            padding-right: .75rem !important; 
            padding-left: 130px;
        }
        .p-button{
            left: 9px;
            right: auto !important; 
        }
    }
    // .footer-wrapper
    .footer-wrapper{
        .social-icon{
            &:first-child{
                margin-right: 0px;
                margin-left: 8px;
            }
            &:last-child{
                margin-left: 0px;
                margin-right: 8px;
            }   
        }
        @media (max-width: 992px) {
            .right-side {
                float: right;
            }
        }
    }
    // onboarding
    .signup-section {
        .signup-right {
            .signup-right-content {
                .with-account-div{
                    &::after{
                        right: auto;
                        left: 0%;
                    }
                }
                .login-with{
                    .login-with-item{
                        padding: 14px 6px 14px 24px !important;
                    }
                }
            }
        }
    }
    // modal
    .p-dialog{   
        .modal-close{
            right: auto !important;
            left: 10px ;
        }
        .p-dialog-content {
            .forgot-password{
                text-align: left;
            }
            .login-with {
                .login-with-item {
                    padding: 14px 6px 14px 24px !important;
                }
            }
        }
    }
    // menu icon
    .p-menuitem-icon{
        margin-left: 8px;
        margin-right: 0px !important;
    }
    // p-badge
    .p-badge{
        margin-left: 0px !important;
        margin-right: 1.25rem !important;
    }
    .detail-section1 {
        .compare-box-bottom .compare-content ~ .compare-content{
            right: auto;
            left: 145px;
        }
        .specification-box::before{
            left: auto;
            right: 90px;
        }
       .quantity-row {
            .p-button.p-button-success {
                margin-right: 0px;
            }
            .p-button-outlined{
                margin-right: 0px;
            }
            .quantity-box{
                margin-right: 0;
                margin-left: 8px;
                @media (max-width: 767px) {
                    .quantity-btn .pi {
                        font-size: 24px;
                    }
                }
            }
       }
    }
    .description-wrap{
        .pl-4{
            padding-left: 0px !important;
            padding-right: 1.5rem !important;
        }
    }
    .react-slider__imgZoom{
        left: auto !important;
        right: 100% !important;
        transform: translateX(0px) !important;
    }
    .verify-otp{
        right: auto;
        left: 0px;
    }
    // cart page
    .cart-parent .cart-right{
        padding-left: 0px;
        padding-right:  50px;
        @media (max-width: 991px) {
            padding-right:  0px;
        }
    }
    .check-right{
        right: auto;
        left: 1rem;
    }
    // bundle detail page
    .bundle-detail-section1 {
        .content-block {
            padding-right: 50px;
            .pl-4 {
                padding-left: 0 !important;
                padding-right: 1.5rem !important;
            }
        }
    }
    // searchresult page
    .product-row {
        .product-box {
            .product-image-box {
                .chips {
                    left: unset !important;
                    right: 0px;
                }
            }
        }
    }
    // hardware page
    .hardware-section1 {
        .ml-2 {
            margin-left: 0 !important;
            margin-right: 0.5rem !important;
        }
    }
    // order page
    .order-outline-box {
        .order-product-img,.img45 {
            margin-right: 0px;
            margin-left: 30px;
            @media (max-width: 576px) {
                margin-left: 16px;
            }
        }
        .img45 {
            margin-right: 0px;
            margin-left: 10px;
        }
        .p2.p2-semibold.mt-2,.flex.align-items-center.justify-content-between~.p2-semibold {
            text-align: left;
        }
    }
    // order detail page
    .order-detail-wrapper {
        .order-detail-header {
            .header__left {
                .pr-2 {
                    padding-right: 0 !important;
                    padding-left: 0.5rem !important;
                }
            }
            .header__right {
                .pl-2 {
                    padding-left: 0 !important;
                    padding-right: 0.5rem !important;
                }
            }
        }
        .order-detail-body {
            .order-tracking-wrapper {
                .pr-1 {
                    padding-right: 0 !important;
                    padding-left: 0.25rem !important;
                }
            }
        }
    }
    .grid {
        .ml-auto {
            margin-left: unset !important;
            margin-right: auto !important;
        }
    }
    .compare-product-wrapper {
        .compare-product-block {
            .bottom-boxes {
                .outline-column {
                    border-left: 1px solid var(--outline);
                    border-right: none;
                }
            }
        }
    }
    // checkout page
    .cart-parent {
        .pl-3 {
            padding-left: 0rem !important;
            padding-right: 1rem !important;
        }
        .accordion {
            .accordion-tab {
                .p-accordion-toggle-icon {
                    right: unset;
                    left: 28px;
                    @media(max-width: 767px) {
                        left: 16px;
                    }
                }
                
                .p-accordion-header-link {
                    @media(max-width: 767px) {
                        padding: 16px 24px 16px 40px;
                    }
                }
            }
        }
        .mr-2 {
            margin-right: 0 !important;
            margin-left: 0.5rem !important;
        }
        .ml-2 {
            margin-left: 0 !important;
            margin-right: 0.5rem !important;
        }
        .pl-4 {
            padding-left: 0rem !important;
            padding-right: 1.5rem !important;
        }
        .pl-2 {
            padding-left: 0rem !important;
            padding-right: 0.5rem !important;
        }
    }
    .p-tieredmenu .p-menuitem-link .p-submenu-icon{
        margin-left: 0px !important;
        margin-right: auto;
        transform: rotate(180deg);
    }
    .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler{
        transform: rotate(180deg);
        &[aria-label="Collapse"]{
            transform: rotate(0deg);
        }
    }
    .p-tree .p-treenode-children{
        padding: 0px 1rem 0px 0px !important;
    }
    .wholesale-box{
        .wholesale-tool-box{     
            @media (max-width: 899px) {      
                left: 0px !important;
                right: auto !important;
            }
        }
    }
    .p-toast-message-icon{
        margin-right: 0px !important;
        margin-left: 12px !important;
    }
    .zoom-slider-parent .favorite-icon{
        right: auto !important;
        left: 10px;
    }
    .slider-wrapper {
        .slider-box {
            .p2::after {
                transform: rotate(132deg) !important;
                margin-right: 8px;
            }
        }
    }
       // product detail
    .wholesale-tool-row {
        .p2:nth-child(1) {
            direction: ltr;
            text-align: right;
            justify-content: end !important;
        }
    }
    .pricebox-p{
        .pricetag-p {
            left: auto !important;
            right: 10px !important;
        }
        .slider-range-input {
            padding-left: 12px !important;
            padding-right: 45px !important;
        }
    }
    // Wishlist page
     .wishlist-box {
        .wishlist-image-box {
            .remove-wishlist-icon{
                right: unset !important;
                left: 12px !important;
            }
        }
    }
    .slider-range-wrap {
        direction: ltr !important;
    }
    .light-box .p-dialog-content .plus-minut-top{
        right: 20px;
        left: auto;
    }
    .order-dtl-productLftCol{
        text-align: right;
        @media (max-width: 576px) {
            .hand-on {
                padding-left: 10px;
            }
            .order-detail-wrapper .hand-on {
                object-fit: contain;
            }
            .order-dtl-productRgtCol {
                margin-left: 0;
                text-align: right!important;
                padding-left: 0;
                padding-right: 67px;
            }
            .order-product-img,.img45 {
                margin-left: 0;
            }            
        }
    }
    .shop-more-prodcut-box {
        .arrowRight {
            right: auto;
            top: 23px;
            transform: rotate(-180deg);
            left: 24px;
        }
    }
    .slider-wrapper {
        .slider-box {
            text-align: center;
            .p2{
                padding-left: 25px;
                display: inline-block !important;
                &::after {
                    position: absolute;
                    left: 0;
                    top: 7px;
                }
            }
        }
    }
    .p-password + label {
        padding-right: 30px !important;
    }
    .changeemilfield{
        input.p-inputtext.p-component.p-filled {
            padding-left: 110px !important;
        }
    }
    .p-radiobutton.p-component + label {
        padding-right: 15px !important;
    }
    @media (max-width: 767px){
        .shop-more-prodcut-box {
            padding: 12px 15px 12px 40px;
             .arrowRight {
                left: 6px;
            }
        }
        .cart-column-right {
            margin-top: 30px;
        }
        .checkout-heading.mb-4 .pl-3 {
            padding-right: 15px !important;
        }
    }
    @media (min-width: 768px) and (max-width: 991px){
        .filter-wrapper {
            padding-left: 15px;
        }
    }
    .p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon{
        margin-left: 0.5rem !important;
        margin-right: 0px !important;
        transform: rotate(180deg);
    }
    .p-accordion .p-accordion-header .p-accordion-header-link[aria-expanded="true"] .p-accordion-toggle-icon{
        transform: rotate(0deg);
    }
    .p-datatable .p-datatable-tbody > tr > td{
        text-align: right;
    }
}