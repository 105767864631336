@import "../../styles.scss";

.compare-product-wrapper {
    .compare-product-block {
        // display: grid;
        // grid-template-rows: auto 1fr 1fr 1fr 1fr 1fr;
        .top-boxes {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        }

        .bottom-boxes {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
            grid-template-rows: minmax(68px, auto);
            border-bottom: 1px solid var(--outline);
            .outline-column {
                background-color: transparent;
                border: 1px solid var(--outline);
                border-left: none;
                border-bottom: none;
                border-top: 0;
            }
            

            .fill-column {
                background-color: var(--outline);
            }

            .p-20 {
                padding: 20px;
                max-width: 208px;
                word-wrap: break-word;
            }

            &:last-child {
                border-bottom: 1px solid var(--outline);
            }
        }
    }

    .p2-bold {
        font-weight: 700;
    }

    .product-blank-view {
        height: 305px;
    }

    .product-top-view {
        height: 305px;
        padding: 20px;
        .product-downloaded {
            .p2-semibold {
                font-weight: 600;
                &:first-of-type {
                    color: #EF4444;
                    -webkit-line-clamp: 1;
                }
            }
            .product-img-block {
                position: relative;
                width: 100%;
                height: 183px;
                object-fit: cover;
                align-items: center;
                align-self: center;
                text-align: center;
                display: flex;
                overflow: hidden;
                margin-bottom: 16px;
                .circle-close {
                    position: absolute;
                    right: 0;
                    top: 0;
                    cursor: pointer;
                }
                img {
                    max-width: 100%;
                    max-height: 100%;
                    width: auto;
                    height: auto;
                    align-items: center;
                    align-self: center;
                    display: flex;
                    margin: 0 auto;
                }
            }
        }
        .product-download {
            width: 166px;
            height: 183px;
            border-radius: 4px;
            background-color: var(--outline);
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 16px;
        }
        .product-dropdown {
            width: 166px;
            outline: none;
            box-shadow: none;
            .p-inputtext {
                height: 40px;
                padding: 10px 10px;
                font-size: 12px;
            }
        }
    }
    @media(max-width: 1299px) {
        overflow-y: auto;
        overflow-x: auto;
        width: 1366px;
    }
}
.grid {
    display: flex !important;
}
.grid-overflow {
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
}
.bottom-boxes:nth-child(2) {
    border-top: 1px solid var(--outline) !important;
}
[dir="rtl"]{
    .compare-product-wrapper {
        .product-top-view {
            .product-downloaded {
                .product-img-block{
                    .circle-close {
                        right: auto;
                        left: 0;
                    }
                }
            }
        }
    }
}
.top-boxes{
    background: #fff;
    transition: .6s ease-in-out;
}
.scrolled-down{
    .top-boxes {
        position: fixed;
        z-index: 1;
        background: #fff;
        top:126px;
        transition: .6s ease-in-out;
       .product-img-block {
            height: 100px !important;
            margin-bottom: 8px !important;
        }
    }
     .product-top-view {
        height: 195px;
        padding: 10px 20px;
    }
    .product-blank-view {
        height: 180px;
    }
    .khjfsdjksdfk {
        position: relative;
        margin-top: 165px;
    }
    @media(max-width: 576px) {
        .top-boxes{
            position: relative !important;
            z-index: 1 !important;
            background: #fff !important;
            top: 0 !important;
        }
        .product-top-view {
            height: 305px !important;
            padding: 20px !important;
        }
        .khjfsdjksdfk{
            position: relative !important;
            margin-top: 0 !important;
        }
    .compare-product-wrapper .product-blank-view {
            height: 305px !important;
        }
        .top-boxes .product-img-block {
            height: 183px !important;
            margin-bottom: 8px !important;
        }
    }
}
.capitalize-first-letter{

}