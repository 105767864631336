@import "../../../styles.scss";

.product-sortby-block {
    padding-top: 0px;
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid $outline;

    .p2 {
        @include p2-paragraph($text, 'OpenSans-SemiBold');
    }
}

.product-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 15px;

    @media (max-width: 992px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 767px) {
        grid-template-columns: repeat(1, 1fr);
    }

    .product-box {
        min-width: 20%;
        cursor: pointer;
        margin-bottom: 40px;
        @media (max-width: 992px) {

            &:nth-child(4),
            &:nth-child(5) {
                display: none;
            }
        }

        .product-image-box {
            position: relative;
            .chips-parent{
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                gap: 4px;
                width: 100%;
                position: absolute;
                left: 0px;
                top: 0px;
                z-index: 8;
            }
            // .chips {
            //     position: absolute;
            //     left: 0px;
            //     top: 0px;
            //     z-index: 10;
            // }
        }

        .product-content {
            .p3 {
                &:nth-child(3) {
                    color: rgba($text, 0.53);
                }
            }
        }
        .truncated-text {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2; /* Number of lines to display */
            -webkit-box-orient: vertical;
          }
    }
}
.compCheckbox{
    label.ml-2{
        font-family: "OpenSans-Regular" !important;
        font-size: 12px !important;
        color: #12262F !important;
    }
}    
.P_addBtn {
    width: 40px !important;
    height: 40px !important;
    color: #fff !important;
  
    position: absolute;
    bottom: 20px;
    right: 10px;
    background: linear-gradient(113.49deg, #E8336E 10.85%, #F9B000 77.92%) !important;
    &:hover{
        background: linear-gradient(113.49deg, #E8336E 10.85%, #F9B000 77.92%) !important;
    }
} 
.maxreach {
    color: #f9af01;
    background: #e9e8e8;
    text-align: center;
    padding: 4px 19px;
    font-size: 14px;
    width: auto;
    border-radius: 26px;
    margin-bottom: 10px;
    display: inline-block;
    position: absolute;
    bottom: 64px;
}
.productSizeBox{
    position: absolute;
    bottom: 20px;
    right: 10px;
    width: 92%;
    text-align: center;
    
    .P_inc_dec_box{
        // display: none;
        background: linear-gradient(113.49deg, #E8336E 10.85%, #F9B000 77.92%);
        border: 1px solid #F9B000;
        height: 40px;
        border-radius: 50px;
        padding-left: 10px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
       .pi {
            background: transparent !important;
            border: 0 !important;
            height: 40px;
            width: 40px;
            color: #fff;
            
        display: flex;
        align-items: center;
        justify-content:center;
        }
        span {
            background: transparent;
            border: 0;
            text-align: center;
            height: 40px;
            width: 40px;
            color: #fff;
            
        display: flex;
        align-items: center;
        justify-content:center;
            cursor: pointer;
        }
    }
}
.compareProductDrawer {
    background: rgb(0 0 0 / 70%);
    height: auto !important;
    .p-sidebar-header {
        border: 12px solid transparent;
        border-bottom: 12px solid  rgb(0 0 0 / 70%);
        height: 10px;
        width: 76px;
        position: absolute;
        top: -24px;
        right: 40px;
        padding: 0 !important;
        button.p-sidebar-close.p-sidebar-icon.p-link {
            margin-right: 10px;
            margin-left: 0px;
            margin-top: 15px;
            svg{
                display: none;
            }
           &:after {
                border: solid #fff;
                border-width: 0 2px 2px 0;
                display: inline-block;
                padding: 3px;
                content: '';
                transform: rotate(45deg);
            }
            &:hover{
                border-color: transparent;
                background: transparent;
            }
            &:focus{
                outline: 0 none;
                outline-offset: 0;
                box-shadow: none;
            }
        }
    }
     .p-sidebar-content {
        padding-top: 20px !important;
        height: auto !important;
        padding-bottom: 20px !important;
    }
    .p-sidebar-content {
        padding-top: 20px;
    }
}
.comapareProductRow{
    .comapareProductCol{
        background: #fff;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        margin-right: 10px;
        padding: 10px;
        width: 17.5%;
        max-width: 17.5%;
        position: relative;
        cursor: pointer;
        .deleteC_product{
            width: 25px;
            height: 25px;
            position: absolute;
            top: -5px;
            right: -5px;
            background: #ccc !important;
            display: none;
            color: #000;
            cursor: pointer;
            border-radius: 50px;
        }
        &:hover{
            .deleteC_product{
                display: flex;
            }
        }
        .p2{
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2; /* Number of lines to display */
            -webkit-box-orient: vertical;
            font-size: 12px;
        }
        .c_productImg{
            width: 60px;
            max-width: 60px;
            height: 60px;
            object-fit: contain;
            object-position: center;
        }
        .h6{
            font-family: "OpenSans-Bold" !important;
            del{
                font-family: "OpenSans-Regular" !important;
                color: $gray;
                font-size: 12px;
            }
        }
        &.c_product_empty_col{
            background-color: transparent;
            background-color: #21212180;
            border: 2px #adadad dashed;
            color: #636363;
            font-family: "OpenSans-Bold" !important;
            font-size: 28px;
            align-items: center;
            justify-content: center;
        }
    }
    .compActopnCol{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .ant-btn{
            background: linear-gradient(113.49deg, #E8336E 10.85%, #F9B000 77.92%) !important;
            color: #fff;
            border: 0;
            padding: 6px 15px;
        }
    }
    @media (max-width: 992px){
        flex-wrap: wrap;
         .comapareProductCol {
            margin-right: 0;
            max-width: 100%;
            width: 100%;
            margin-bottom: 10px;
        }
        .compActopnCol {
            width: 100%;
            display: flex;
            justify-content: space-between;
            .ant-btn {
                padding: 0 20px;
            }
        }
    }
}
[dir="rtl"]{
    .compareProductDrawer .p-sidebar-header{
        button.p-sidebar-close.p-sidebar-icon.p-link {
            margin-right: 0;
            margin-left: 10px;
            margin-top: 15px;
        }
    }

}
