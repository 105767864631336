@import "../../../styles.scss";

.bundle-section1 {
    padding: 16px 0px 40px 0px;
    min-height: calc(100vh - 442px);

    .home1-image-box {
        position: relative;
        height: 160px;
        overflow: hidden;
        width: 100%;
        border-radius: 0.25rem;
        margin-bottom: 30px;

        .home1-img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            transition: all 0.3s ease-in-out;
        }

        .overlay-text {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0);
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            padding: 0px 250px 0px 52px;

            @media (max-width: 767px) {
                padding: 15px 16px 15px 16px;
            }

            .h2 {
                color: $white;
                margin-bottom: 4px;
            }

            .p2 {
                color: $outline;
                line-height: 20px;
            }
        }

        &:hover {
            .home1-img {
                transform: scale(1.05);
            }

            .overlay-text {
                .link {
                    color: $yellow;

                    .pi {
                        color: $yellow;
                        transform: translateX(5px);
                    }
                }
            }
        }
    }

    .heading-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;

        @media (max-width: 576px) {
            flex-direction: column;
            align-items: flex-start;

            .link {
                margin-top: 8px;

                .link-content {
                    margin: 0px 0px 0px 0px !important;
                }
            }
        }
    }

    .product-row {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 12px;

        @media (max-width: 992px) {
            grid-template-columns: repeat(3, 1fr);
        }

        @media (max-width: 767px) {
            grid-template-columns: repeat(1, 1fr);
        }

        .product-box {
            min-width: 20%;
            cursor: pointer;
            @media (max-width: 992px) {

                &:nth-child(4),
                &:nth-child(5) {
                    display: none;
                }
            }

            .product-image-box {
                position: relative;
                .chips-parent{
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;
                    gap: 4px;
                    width: 100%;
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    z-index: 10;
                }
                // .chips {
                //     position: absolute;
                //     left: 0px;
                //     top: 0px;
                //     z-index: 10;
                // }
            }

            .product-content {
                .p3 {
                    &:first-of-type {
                        color: rgba($text, 0.53);
                    }
                }
            }
        }
    }
}