@import "../../../styles.scss";

// detail zoom slider
// .react-slider{
//     display: flex;
//     flex-direction: row-reverse;
//     &__ul{
//         flex-flow: column wrap !important;
//         min-width: 80px;
//         margin-top: 0px !important;
//         li{
//             width: 78px !important;
//             height: 78px !important;
//             min-width: 78px !important;
//             min-height: 78px !important;
//             max-width: 78px !important;
//             max-height: 78px !important;
//             margin: 0px 0px 8px 0px !important;
//             display: flex;
//             align-items: center;
//             justify-content: center;
            
//             img{
//                 width: calc(100% - 0px);
//                 height: calc(100% - 0px);
//                 object-fit: scale-down;
//                 padding: 8px;
//                 border: 1px solid var(--outline);
//                 border-radius: 4px; 
//             }
//             &.active {
//                 img {
//                     border: 1px solid var(--yellow) !important;
//                     border-radius: 4px;
//                 }
//             }
//         }
//     }
//     &__container{
//         width: 650px;
//         height: 452px;  
//         @media (max-width: 960px) {
//             height: auto;
//         }      
//         .react-slider__picture{
//             width: 100%;
//             height: 100%;
//             max-height: 452px;
//             img{
//                 width: 100%;
//                 height: 100%;
//                 object-fit: scale-down;
//                 max-height: 452px;
//                 padding: 10px;
//                 border: none !important;
//             }
//         }
//         button{
//             background: transparent !important;
//             &::before{
//                 border-color: var(--text) !important;
//             }
//         }
        
//     }   
//     &__imgZoom{
//         transform: translateX(0) !important;
//         // z-index: 999 !important;
//         max-height: 452px;
//         @media (max-width: 960px) {
//             display: none !important;
//         }
//     }
// }

.p-galleria{
    margin-bottom: 30px;
    .p-galleria-content{
        @media (max-width: 600px) {
            // pointer-events: none !important;
        }
        .p-galleria-item-wrapper{
            width: 100%;
            .p-galleria-item-container{
                width: 100%;
                .p-galleria-item-nav{
                    background-color: transparent;
                    box-shadow: none !important;
                    width: 20px !important;
                    height: 20px !important;
                    margin: 0px 1rem;
                    .p-galleria-item-prev-icon,.p-galleria-item-next-icon{
                        color: var(--text) !important;
                        font-size: 1rem !important;
                        transform: scale(0.80);
                    }
                }
            }
        }
        .p-galleria-thumbnail-wrapper{
            .p-galleria-thumbnail-container{
                background: transparent;
                height: 450px;
                padding: 0;
                .p-galleria-thumbnail-prev,.p-galleria-thumbnail-next{
                    position: absolute;
                    display: none;
                }
                .p-galleria-thumbnail-items-container{
                    // height: 100% !important;
                    @media (max-width: 600px) {
                        display: none !important;
                    }
                    .p-galleria-thumbnail-items{
                        .p-galleria-thumbnail-item{
                            opacity: 1 !important;
                            .p-galleria-thumbnail-item-content{
                                width: 78px;
                                height: 78px;
                                min-width: 78px;
                                min-height: 78px;
                                max-width: 78px;
                                max-height: 78px;
                                margin: 0px 0px 8px 0px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                box-shadow: none !important;
                                img.thumbnail-img{
                                    width: calc(100% - 0px);
                                    height: calc(100% - 0px);
                                    object-fit: scale-down;
                                    padding: 8px;
                                    border: 1px solid var(--outline);
                                    border-radius: 4px; 
                                    transition: all 0.3s ease-in-out;
                                }
                            }
                            &.p-galleria-thumbnail-item-current{
                                .p-galleria-thumbnail-item-content{
                                    img.thumbnail-img{
                                        border: 1px solid var(--yellow);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.light-box{
    .p-dialog-content{
        border-radius: 0rem 0rem 6px 6px !important;
        position: relative;
        overflow: visible !important;
        .plus-minut-top{
            position: absolute;
            left: 20px;
            top: -60px;
            .plus-minus-btn{
                border-radius: 50%;
                background: transparent;
                border: none;
                color: var(--text);
                padding: 10px 10px;
                opacity: 0.5;
                transition: ease-in-out 0.3s;
                &:hover{
                    opacity: .75;
                }
            }
        }
        .zoom-in-trans{
            transition: ease-in-out 0.3s;
        }
        .p-galleria-item-container{
            min-height: 65vh;
            align-items: center;
        }
    }
}
.zoom-slider-parent{
    position: relative;
    .favorite-icon{
        position: absolute;
        top: 0px;
        right: 10px;
        z-index: 999;
        padding: 10px 10px;
        transition: ease-in-out 0.3s;
        .pi{
            background: transparent;
            border: none;
            font-size: 1.25rem;
            &:hover{
                color: var(--error);
            }
        }
        
    }
}
// .react-slider{
//     img {
        
//     }
//     &__ul {
//         margin-top: 0px !important;
//         li {
//             &.active {
//                 img {
//                     border: 1px solid var(--yellow);
//                     border-radius: 4px;
//                 }
//             }
//         }
//     }
// }