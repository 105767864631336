@import "../../../styles.scss";

.searchresult-section1 {
    padding: 40px 0px;
    min-height: calc(100vh - 445px);

    .search-result-head {
        padding: 10px 0px 30px 0px;
        margin-bottom: 30px;
        border-bottom: 1px solid var(--outline);
    }

    .product-row {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 16px;
        @media (max-width: 992px) {
            grid-template-columns: repeat(3, 1fr);
        }
        @media (max-width: 767px) {
            grid-template-columns: repeat(1, 1fr);
        }
        .product-box {
            cursor: pointer;
            .product-content {
                .p3 {
                    &:nth-child(3) {
                        color: rgba($text, 0.53);
                    }
                }
            }
            .product-image-box {
                .chips-parent{
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;
                    gap: 4px;
                    width: 100%;
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    z-index: 10;
                }
                // .chips {
                //     position: absolute;
                //     left: 0px;
                //     top: 0px;
                //     z-index: 10;
                // }
            }
            .truncated-text {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2; /* Number of lines to display */
                -webkit-box-orient: vertical;
              }
        }
    }
}