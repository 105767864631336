@import "../../styles.scss";
.wishlist-section1 {
    padding: 16px 0px 90px 0px;
    min-height: calc(100vh - 442px);
    .h2{
        margin-bottom: 24px;
    }
    // wishlist box
    .wishlist-row{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        row-gap: 40px;
        column-gap: 80px;
        @media (max-width: 1199px) {
            row-gap: 20px;
            column-gap: 20px;
        }
        @media (max-width: 992px) {
            grid-template-columns: repeat(3, 1fr);
        }
        @media (max-width: 767px) {
            grid-template-columns: repeat(1, 1fr);
        }
        .wishlist-box{
            transition: all 300ms;
            .wishlist-image-box{
                width: 100%;
                height: 243px;
                background-color: $white;
                border-radius: 8px;
                overflow: hidden;
                display: flex;  
                align-items: center;
                justify-content: center;
                position: relative;
                .wishlist-image{
                    width: 100%;
                    height: 100%;
                    object-fit: scale-down;
                    transition: all 300ms;
                    transform: scale(1);
                    transform-origin: center center;
                }
                .remove-wishlist-icon{
                    position: absolute;
                    top: 12px;
                    right: 12px;
                    height: 16px;
                    width: 16px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    z-index: 2;
                }
                .out-of-stock{
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    height: calc(100%);
                    width: 100%;
                    background: #D9D9D94D;
                    border-radius: 4px;
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;
                    padding-bottom: 12px;
                    .out-of-stock-btn{
                        background: $white;
                        color: $error;
                    }
                }
            }
            .wishlist-content{
                padding: 0px 0px;
                text-align: center;
                .p3{
                    @include p3-paragraph($pink, $family: 'OpenSans-Semibold');
                    margin-bottom: 8px;
                }
                .h6{
                    @include h6-heading($text, $family: 'OpenSans-bold');   
                    margin: auto auto 8px auto;
                    font-size: 14px;
                    white-space: nowrap;
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis; 
                }                
                .truncated-text {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1; /* Number of lines to display */
                    -webkit-box-orient: vertical;
                    width: 100%;
                    max-width: 224px;
                    white-space: normal;
                  }
                .price-row{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .p2{
                        @include p2-paragraph($text, $family: 'OpenSans-bold');
                        margin-bottom: 0px;
                        text-align: center;
                    }
                    .p3{
                        @include p3-paragraph($error, $family: 'OpenSans-Regular');
                        margin: 0px 8px;
                        text-align: center;
                    }
                    .cross{
                        text-decoration: line-through;
                    }
                }
                .add-to-card-btn{
                    height: 54px;
                    width: calc(100% - 0px);
                    background-color: $cta2;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 10px;
                }
            }
            &:hover{
                .wishlist-image-box{
                    .wishlist-image{
                        transform: translateY(-2px);
                    }
                }
            }
        }
    }
    .empty-page{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 80px 0px 40px 0px;
        .empty-image{
            width: 100%;
            max-width: 198px;
            object-fit: contain;
            margin-bottom: 36px;
        }
        .h3{
            margin-bottom: 8px;
        }
        .p2{
            margin-bottom: 32px;
        }
        .p-button{
            width: 300px;
            justify-content: center;
        }
    }
}


.truncated-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Number of lines to display */
    -webkit-box-orient: vertical;
  }
  .empty-parent{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center; 
    min-height: calc(100vh - 539px);
  }