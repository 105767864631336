@import "../../../styles.scss";
.bundle-detail-section1 {
    padding: 0px 0px 40px 0px;
    min-height: calc(100vh - 442px);
    .description-wrap{
        .h3 {
            @include h3-heading($text);
        }
        .wraplist{
            width: 120px;
            display: inline-flex;
        }
        p{
            @include p2-paragraph($text, $family: 'OpenSans-Regular');
            line-height: 22px;
        }
        ul,ol{
            padding: 0px 20px;
            margin-bottom: 16px;
            li{
                @include p2-paragraph($text, $family: 'OpenSans-Regular');
                line-height: 20px;
                margin-bottom: 8px;
            }
        }
    }
    .content-block{
        padding-left: 50px;
        .p2 {
            &.p2-bold{
                font-family: "OpenSans-Bold";
            }
        }
    }
    .product-top-text{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .p2{
            @include p2-paragraph($text, $family: 'OpenSans-Semibold');
            &.green{
                color: $pink;
            }   
        }
    }
    .share-compare-row{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        .p2{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            cursor: pointer;
            .icon24{
                width: 24px;
                height: 24px;
            }
            span{
                @include p2-paragraph($text, $family: 'OpenSans-Semibold');
                margin: 0px 8px;
                display: inline-block;
            }
            &.yellow{
                span{
                    color: $yellow;
                }
            }
        }
        .compare-box{
            padding: 0px 10px 0px 14px;
            margin: 0px 4px;
            position: relative;
            &::after{
                content: '';
                position: absolute;
                top: 50%;
                right: 0px;
                transform: translateY(-50%);
                width: 1px;
                height: 16px;
                background-color: $text;
            }
        }
        .wholesale-box{
            position: relative;
            cursor: pointer;
            .wholesale-tool-box{
                padding: 16px 24px;
                border-radius: 8px;
                background: $white;
                border: 1px solid $outline;
                width: 260px;
                position: absolute;
                left: 0px;
                right: auto;
                top: 100%;
                transform: translateY(-15px);
                transition: all 300ms;
                z-index: 99;
                visibility: hidden;
                opacity: 0;
                .wholesale-tool-head{
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    padding-bottom: 10px;
                    margin-bottom: 5px;
                    border-bottom: 1px solid $outline;
                    .p2{
                        width: 60%;
                        &~.p2{
                            width: 40%;
                        }
                    }
                }
                .wholesale-tool-body{
                    .wholesale-tool-row{
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        padding: 4px 0px;
                        .p2{
                            @include p2-paragraph($text, $family: 'OpenSans-Semibold');
                            width: 60%;
                            &~.p2{
                                width: 40%;
                            }
                        }
                    }
                }
            }
            &:hover{
                .wholesale-tool-box{
                    transform: translateY(3px);
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
    }    
    .socail-box-parent{
        box-shadow: 0px 1px 20px 0px rgba($color: #000000, $alpha: .30);
        position: absolute;
        top: 30px;
        border-radius: 0.5rem;
        width: 100px;
        padding: 10px;
        background: var(--white);
        z-index: 5;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: 40px;
        align-items: center;
        justify-content: center;
        .social-icon{
            cursor: pointer;
            margin: auto;
        }

    }
    .sort-description{
        margin: 16px 0px;
        line-height: 20px;
    }
    .line-ellip4{
        display: -webkit-box;
        -webkit-line-clamp: 4;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
    }
    .in-stock{
        @include p2-paragraph($cta2, $family: 'OpenSans-Semibold');
        display: flex;
        align-items: center;
        .pi{
            color: $cta2;
        }
        span{
            margin: 0px 6px;
        }
    }
    .outOff-stock{
        @include p2-paragraph($error, $family: 'OpenSans-Semibold');
        display: flex;
        align-items: center;
        .pi{
            color: $error;
        }
        span{
            margin: 0px 6px;
        }
    
    }
    .price-row{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 8px 0px;
        .h6{
            @include h6-heading($text, $family: 'OpenSans-Bold');
        }
        .p3{
            color: $error;
            margin: 0px 8px;
        }
        .cross{
            text-decoration: line-through;
        }
    }
    .quantity-row{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 16px;
        margin-bottom: 8px;
        gap: 8px;
        @media (max-width: 599px) {
            display: block;
        }
        .quantity-box{
            width: 56px;
            height: 56px;
            border: 1px solid $outline;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 65px;
            @media (max-width: 599px) {
                width: 100%;
            }
            input{
                border: none;
                width: auto;
                box-shadow: none;
                max-width: 30px;
                text-align: center;
                min-width: 25px;
                &::-webkit-outer-spin-button,&::-webkit-inner-spin-button{
                    -webkit-appearance: none;
                    margin: 0;
                }
                &[type=number]{
                    -moz-appearance: textfield;
                }
                &:focus,&:active{
                    outline: none;
                }
            }
            .quantity-btn{
                cursor: pointer;
                display: flex;
                flex-direction: column;
                &:focus,&:active{
                    outline: none;
                }
                .pi{
                    color: $gray;
                    font-size: 12px;
                    &:hover,&:focus,&:active{
                        color: $text;
                    }
                }
            }
        }    
        .p-button{
            width: calc(100% - 64px);
            &.p-button-success{
                margin-right: 15px;
            }
            @media (max-width: 599px) {
                width: 100%;
                margin-top: 16px;
            } 
        }
    }
    .product-features{
        margin-top: 30px;
        margin-bottom: 24px;
        .p2{
            margin-bottom: 12px;
            display: flex;
            align-items: center;
            .pi{
                color: $error;
            }
            span{
                @include p2-paragraph($text, $family: 'OpenSans-Regular');
                margin: 0px 8px;
                b{
                    @include p2-paragraph($text, $family: 'OpenSans-Bold');
                }
            }
        }
    }
    .product-image{
        width: 25%;
        height: 25%;
        object-fit: scale-down;
        transition: all 300ms;
        transform: scale(1);
        transform-origin: center center;
    }
}