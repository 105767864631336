@import "../../../styles.scss";

.signup-section{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    @media (max-width: 767px) {
        flex-direction: column;
    }
    .signup-left{
        background: url('../../../assets/images/structure/signup-bg.jpg') no-repeat center center fixed;
        background-size: cover;
        height: 100vh;
        width: 455px;
        position: relative;
        @media (max-width: 1199px) {
            width: 340px;
        }
        @media (max-width: 767px) {
            height: 300px;
            width: 100%;
        }
        .signup-left-content{
            position: absolute;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-direction: column;
            padding: 24px 20px 50px 24px;
            @media (max-width: 767px) {
                padding: 24px 20px 20px 20px;
            }
            .signup-left-logo{
                width: auto;
                img{
                    width: 100%;
                    height: 42px;
                    object-fit: scale-down;
                    @media (max-width: 576px) {
                        height: 34px;
                    }
                }
            }
            .h1{
                font-family: "OpenSans-SemiBold";
                line-height: 65px;
                letter-spacing: -4px;
                margin-bottom: 16px;
                @media (max-width: 1199px) {
                    line-height: 42px;
                }
            }
            .p2{
                max-width: 180px;
                line-height: 20px;
            }
            .p1{
                .yellow{
                    @include p1-paragraph($yellow, $family: "OpenSans-Bold");
                    text-decoration: none;
                    cursor: pointer;
                }
            }
        }
    }
    .signup-right{
        padding: 0px 20px;
        width: calc(100% - 455px);
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        scrollbar-color: #f1f1f1 transparent;
        scrollbar-width: thin;
        &::-webkit-scrollbar {
            width: 4px;
            height: 4px;
            background-color: transparent;
            border-radius: 2px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #f1f1f1;
            border-radius: 2px;
        }
        &::-webkit-scrollbar-track {
            border: 0.0625rem solid transparent;
            border-radius: 2px;
        }
        @media (max-width: 1199px) {
            width: calc(100% - 330px);
        }
        @media (max-width: 767px) {
            height: auto;
            width: 100%;
        }
        .signup-right-content{
            max-width: 704px;
            margin: 40px auto;
            &.max-width340{
                max-width: 340px;
                width: 100%;
                .icon66{
                    margin-bottom: 32px
                }
            }
            .p2{
                margin-bottom: 40px;
            }
            .with-account-div{
                @include p2-paragraph($text, $family: "OpenSans-Regular");
                width: 100%;
                position: relative;
                margin: 30px 0px;
                &::after{
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: auto;
                    right: 0%;
                    width: calc(100% - 180px);
                    height: 1px;
                    background: $outline;
                }
            }
            .login-with{
                display: flex;
                align-items: center;
                justify-content: space-between;
                .login-with-item{
                    background: transparent;
                    border-radius: 4px;
                    border: 1px solid #dadce0;
                    color: #3c4043;
                    height: 40px;
                    padding: 14px  24px 14px 6px;
                    width: calc(50% - 12px);
                    display: flex;
                    justify-content: center ;
                    .icon24{
                        margin: 0px 14px;
                    }
                }
            }
        }
    }
}

.terms-and-conditions{
    max-height: 420px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-color: $inside $outline;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        background-color: $outline;
        border-radius: 2px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: $inside;
        border-radius: 2px;
    }
    &::-webkit-scrollbar-track {
        border: 0.0625rem solid $outline;
        border-radius: 2px;
    }
    ul{
        padding-left: 0px;
        list-style: none;
        margin-bottom: 20px;
    }
    .width176{
        width: 176px;
    }
}
.p-float-label{
    .passwordIcon {
        cursor: pointer;
        position: absolute;
        right: 10px;
        top: 20px;
        color: #6c757d;
    }
}
@media (max-width: 991px) {
    .signup-section {
        .signup-right {
            .signup-right-content {
                max-width: 95%;
                .login-with {
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                     [style="width: 50%; box-sizing: border-box;"] {
                        width: 100% !important;
                        display: flex;
                        justify-content: center;
                        margin-top: 10px;
                    }
                   .login-with-item {
                        width: 100%;
                    }
                }
            }
        }
    }
}
.terms-and-conditions{
    text-align: justify;
    padding-right: 10px;
}