@import '../../../styles.scss';
.header-checkout{
    height: 70px;
    width: 100%;
    box-shadow: 0px 2px 4px 0px #0000000A;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $text;
    position: relative;
    z-index: 99;
    .header-flex{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .down-arrow{
            filter: contrast(0) brightness(5);
        }
        .header__logo{
            width: auto;
            height: auto;
            margin: 0 0px;
            img{
                height: 34px;
                position: relative;
                bottom: -2px;
            }
        }
        .header__user{
            display: flex;
            align-items: center;
            margin: 0px 30px;
            cursor: pointer;
            @media (max-width: 899px) {
                margin: 0px 0px;
            }
            span{
                display: flex;
                align-items: center;
                .pi-angle-down{
                    color: $white;
                }
            }
        }
    }
    .card-payment-tab{
        width: 490px;
        margin: auto;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media (max-width: 767px) {
            width: 280px;
        }
        @media (max-width: 576px) {
            width: auto;
        }
        &:after{
            content: '';
            position: absolute;
            height: 1px;
            border-bottom: 1px dashed $white;
            width: 100%;
            left: 0px;
            right: 0px;
            margin: auto;
        }
        .option{
            display: flex;
            align-items: center;
            background: $text;
            position: relative;
            z-index: 1;
            padding: 0px 10px 0px 0px;
            @include p3-paragraph($white);
            @media (max-width: 576px) {
                padding: 0px 0px 0px 0px;
            }
            .count{
                width: 28px;
                height: 28px;
                border-radius: 50%;
                border: 1px solid $white;
                @include p3-paragraph($white);
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0px 8px;
            }
            .mobile-none{
                @media (max-width: 576px) {
                    display: none;
                }
            }
            &.active{                
                .count{
                    background: $yellow;
                    border: 1px solid $yellow;
                }                
            }
        }
    }
}